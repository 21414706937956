// external libraries
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCardAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

// custom component styling
import "./index.css";


// component showing the images input and handling the functionality. When designing the component, the following was considered:
// - only image urls are stored in mongodb
// - updating the images requires an actual "file" that is uploaded to django
// - every "file" is an object coninting:
//  {
//      isMain: true/false of whether the image is the main image
//      file: the actual file to be uploaded
//  }
// therefore, the component does the following
// - converts all image urls to files and provides preview to the user
// - allows the user to select and deselct images to be uploaded and which image is the main image
// - updates the formState after every change. formState is the local state variable holding all product input fields that will be sent to django
const AdminProductImagesInput = ({ productDetails, onChange, formState }) => {
    // the main idea behind this component is:
    // - since only the url of images are stored in mongo, 
    const noImage = "/media/products/no-product-image.jpg";
    const { images, files } = formState[0];

    const urlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], url.split("/").pop(), { type: blob.type });
    };

    // once the images are loaded, convert them to files.
    // - fetching images is async process so we wait for all image urls to be converted to files
    useEffect(() => {
        const fetchImages = async () => {
            const imageFiles = await Promise.all(images.map(async (image, index) => {
                return { file: await urlToFile(image.url), isMain: images[index].isMain };
            }));
            const fakeEvent = {
                currentTarget: {
                    name: 'files',
                    value: imageFiles
                }
            };
            onChange(fakeEvent);
        };
        fetchImages();
    }, [images]);

    // handles image additions
    // when user clicks on the upload file, the formState files are updated with the new addition; urls are updated only after images are uploaded and saved
    const handleImageAddition = (event) => {
        event.preventDefault();
        const fakeEvent = {
            currentTarget: {
                name: 'files',
                // defaulting the first image as main image
                value: [...files, { file: event.target.files[0], isMain: false || files.length === 0 }]
            }
        };
        onChange(fakeEvent);
        event.target.value = null;
    };

    // handles the removal of the images once the user clicks on the "trash" icon on the image
    // filters the image by index that is stored in the data- property
    const handleImageRemoval = (event) => {
        event.preventDefault();
        const { imageIndex } = event.currentTarget.dataset;
        const fakeEvent = {
            currentTarget: {
                name: 'files',
                value: files.filter((image, index) => index !== parseInt(imageIndex))
            }
        };
        onChange(fakeEvent);
    };

    // handles the selection of the main image
    // since there can be only one main image, everytime, the selection is changed, the previous main image is deselected
    const handleMainImageSelection = (event) => {
        const fakeEvent = {
            currentTarget: {
                name: 'files',
                value: files.map((image, index) => {
                    return { ...image, isMain: index === parseInt(event.currentTarget.dataset.imageIndex) };
                })
            }
        };
        onChange(fakeEvent);
    };

    // component display
    // only the label of the file upload input is chosen in order to prevent the display of "no image selected" that always appears right after the image input
    // the buttons here (main image and trash) do not follow the standard component. This will be work-in-progress for future release
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start align-items-center mb-3">
            <input type="file" onChange={(event) => handleImageAddition(event)} accept="image/png, image/jpeg, image/gif" style={{ display: 'none' }} id="fileInput" />
            <label htmlFor="fileInput" className="btn btn-primary">
                {'Add Image'}
            </label>
            <div className="d-flex justify-content-center carousel-indicators col-12 overflow-x-auto">
                <div className="d-flex flex-wrap justify-content-start " >
                    {files?.map((imageFile, index) => {
                        return (
                            <div className="d-flex col-6 p-2" key={index}>
                                <div className="d-flex col-12 flex-column p-2 border border-1 rounded shadow-sm ">
                                    <div className="d-flex col-12 align-items-center">
                                        <div className="d-flex col-6 align-items-center justify-content-start">
                                            <button type="button" className={`d-flex btn ${imageFile.isMain ? "btn-secondary" : "btn-outline-primary"}`} onClick={(event) => handleMainImageSelection(event)} data-image-index={index}>
                                                <FontAwesomeIcon icon={faIdCardAlt} />
                                            </button>
                                        </div>
                                        <div className="form-check d-flex col-6 align-items-center justify-content-end">
                                            <button type="button" className="d-flex btn btn-outline-primary" onClick={(event) => handleImageRemoval(event)} data-image-index={index}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex p-2 active " >
                                        <img src={URL.createObjectURL(imageFile.file) || noImage} onError={(e) => e.target.src = noImage} className="d-flex col-12 img-fluid" alt={`Product illustration ${index + 1}`} />
                                    </div>
                                </div>
                            </div>
                        );

                    })}
                </div>
            </div>
        </div>
    );
};

export default AdminProductImagesInput;