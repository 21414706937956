// external libraries
import React from "react";
import { useSelector } from "react-redux";

// components
import AdminDisplayProductCard from "../Card";

// redux slice
import { selectAllProducts, selectProductsByCategory } from "../../../../../features/products/productsSelectors";
import { SUCCESS } from "../../../../../features/status";


// displays all products that are saved in the database
// future state - have an indicator which product is active and which isn't
const AdminProductList = ({ title, wrap = true}) => {
    const success = useSelector(state => state.products.status === SUCCESS);
    let productList = useSelector(state => selectAllProducts(state));

    // if no products exists after loading, return no products to show message
    if (success && productList.length === 0) {
        return <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <div className="d-flex flex-column justify-content-center align-items-center fs-1 mb-2 text-secondary col-12">
                <span className="fst-italic">No Products to Show</span>
            </div>
        </div>;
    }

    // return the product listing
    return (
        <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <div className="d-flex flex-column justify-content-center align-items-center fs-1 mb-2 text-secondary col-12">
                <span className="fst-italic">{title}</span>
            </div>
            <div className="d-flex overflow-x-auto col-12 col-md-10 p-2 justify-content-center">
                <div className={`d-flex ${wrap && "flex-wrap"} col-12 justify-content-start`}>
                    {
                        productList.map((product) => {
                            return (
                                <div className="d-flex p-2 col-11 col-sm-5 col-md-4 col-xl-3" key={product.id}>
                                    <AdminDisplayProductCard product={product} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminProductList;