// external libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// components
import Loading from "../../../components/common/Loading";

// redux slice
import { FAILED, LOGIN_ERROR, SUCCESS } from "../../../features/status";
import { selectAllCartProducts, selectCartId } from "../../../features/cart/cartSelectors";
import { createOrder } from "../../../features/orders/ordersSlice";
import { deleteCart } from "../../../features/cart/cartSlice";


// successpage after user has successfully made a payment
const Success = () => {
    const userInfoLoaded = useSelector(state => state.user.status === SUCCESS);
    const error = useSelector(state => state.user.status === FAILED || state.user.status === LOGIN_ERROR);

    // get the ?session id from query parameter and remove curly braces
    const [searchParams] = useSearchParams();

    // create the order
    const productsInCart = useSelector(state => selectAllCartProducts(state));
    const cartID = useSelector(state => selectCartId(state));
    const [orderNumber, setOrderNumber] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateToHomepage = () => {
        navigate("/", { replace: true });

    };

    // since we are redirected back to the site after payment, we need to confirm the order
    useEffect(() => {
        const confirmOrder = async () => {
            const sessionId = searchParams.get("session_id");
            if (sessionId && cartID) {
                // creates the order
                return dispatch(createOrder({
                    "session_id": sessionId.slice(1, -1) || "",
                    "products": productsInCart
                })).then(order => {
                    // once order is created, the number is displayed and the cart is deleted
                    setOrderNumber(order?.payload?.data?.upsertOrder?.order?.id);
                    deleteCartInfo();
                });

            } else {
                // redirect to homepage if there was an error with the session or cart
                navigateToHomepage();
            }
        };
        const deleteCartInfo = async () => {
            dispatch(deleteCart(cartID));
        };
        // once the user info is loaded, then we create order
        if (userInfoLoaded) {
            confirmOrder();

        }

    }, [userInfoLoaded]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // page shown when user successfuly makes a payment, but there was an error on the application side
    if (error) {
        return (
            <main className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-center align-items-center alert alert-light gap-2 mt-5">
                    <FontAwesomeIcon icon={faCircleXmark} className="fs-1 mb-2 text-primary" />
                    <span className="fs-4">We have received your payment, but we have problem processing your order. Please contact customer support quoting the following reference number:
                        <span className="text-primary fs-3"> {searchParams.get("session_id").slice(1, -1) || ""}</span>
                    </span>
                </div>

            </main>);
    }


    // page shown when user successfuly makes a payment and order is created
    if (userInfoLoaded && orderNumber) {
        return (
            <main className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-center align-items-center alert alert-light gap-2 mt-5">
                    <FontAwesomeIcon icon={faCheckCircle} className="fs-1 mb-2 text-primary" />
                    <span className="fs-4">We have received your order. For your reference your order ID is:
                        <span className="text-primary fs-3"> {orderNumber}</span>
                    </span>
                </div>

            </main>
        );
    }

    // default state of the page is loading; user info must be loaded as well as order number created before that information is displayed to clients
    return (
        <main className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-start align-items-center alert alert-light gap-2">
                <Loading />
            </div>

        </main>
    );

};

export default Success;

