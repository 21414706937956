// function to display the currency in the correct format
export const formatCurrency = (price) => {
    if (typeof price?.value === 'number') {
        return new Intl.NumberFormat(navigator.userLocale, {
            style: 'currency',
            currency: price?.currency?.code,
            currencyDisplay: 'code'
        }).format(price?.value);
    } else {
        return "N/A";
    }
};

export const formatDimensionScale = (dimensionScale) => {
    if (typeof dimensionScale?.value === 'number') {
        return new Intl.NumberFormat(navigator.userLocale, {
            maximumFractionDigits: 2,
            style: "unit",
            unit: (dimensionScale?.scale?.name).toLowerCase(),
            unitDisplay: "short"
        }).format(dimensionScale.value);
    } else {
        return "N/A";
    }
};

