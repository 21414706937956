//  external libraries
import { gql } from "@apollo/client";


// mutation to login a user and get token
export const LOGIN_USER = gql`
    mutation LoginUser($email: String, $password: String) {
        loginUser(email: $email, password: $password) {
            token
        }
    }
`;

// mutation to register a user and get token
export const REGISTER_USER = gql`
    mutation RegisterUser($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
        registerUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
        ) {
            token
        }
    }
`;