// external libraries
import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// components
import Loading from "../../../common/Loading";

// utilities
import { checkObject } from "../../../../utils/ui";
import { formatCurrency } from "../../../../utils/numberFormats";


// this is the compoenent that displays the order summary
// - loading: boolean to determine if the order is still loading
// - orderDetails: the details of the order that is fetched from the stripe API (hence we need the loading component)
const OrderSummary = ({ loading, orderDetails }) => {
    // if the order is still loading, display the loading component
    if (loading) {
        return <Loading />;
    }

    // returns the order summary component - the component encompasses the entire div, the title, and the order details
    return (
        <div className="border border-1 rounded shadow p-2 mb-2">
            <div className="d-flex col-12 align-items-center">
                <h3 className="d-flex gap-2 mb-0">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <span>Order Summary</span>
                </h3>
            </div> {/* this is the title */}
            {checkObject(orderDetails) ? "" :
                <Fragment >
                    <hr className="mb-4" />
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Date</h5></span>

                        <span className="d-flex text-primary"><h5>{dayjs.unix(orderDetails.created).format("YYYY-MM-DD HH:MM:ssZ")}</h5></span>
                    </div>
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Status</h5></span>

                        <span className="d-flex text-primary"><h5>{orderDetails.payment_status}</h5></span>
                    </div>
                    <hr className="mb-4" />
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Name</h5></span>

                        <span className="d-flex text-primary"><h5>{orderDetails.customer_details.name}</h5></span>
                    </div>
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Email</h5></span>

                        <span className="d-flex text-primary"><h5>{orderDetails.customer_details.email}</h5></span>
                    </div>
                    <hr className="mb-4" />
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Subtotal</h5></span>

                        <span className="d-flex text-primary"><h5>{formatCurrency({ currency: {code: orderDetails.currency}, value: orderDetails.amount_subtotal / 100 })}</h5></span>
                    </div>
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Discounts</h5></span>

                        <span className="d-flex text-primary"><h5>{formatCurrency({currency: {code: orderDetails.currency}, value:orderDetails.total_details.amount_discount / 100})}</h5></span>
                    </div>
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Shipping</h5></span>

                        <span className="d-flex text-primary"><h5>{formatCurrency({currency: {code: orderDetails.currency}, value:orderDetails.total_details.amount_shipping / 100})}</h5></span>
                    </div>
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Taxes</h5></span>

                        <span className="d-flex text-primary"><h5>{formatCurrency({ currency: { code: orderDetails.currency }, value: orderDetails.total_details.amount_tax / 100 })}</h5></span>
                    </div>
                    <hr className="mb-4" />
                    <div className="d-flex col-12 justify-content-between">
                        <span className="d-flex"><h5>Total</h5></span>

                        <span className="d-flex text-primary"><h5>{formatCurrency({ currency: { code: orderDetails.currency }, value: orderDetails.amount_total / 100 })}</h5></span>
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default OrderSummary;