// external libraries
import React from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

//components
import AdminPriceInformation from "../../Common/PriceInfo";
import Loading from "../../../../common/Loading";
import AdminActionButton from "../../../ActionButton";
import AdminProductImages from "../../Common/Images";

// redux slice
import { useSelector } from "react-redux";
import { LOADING, LOGIN_ERROR } from "../../../../../features/status";
import { selectCustomerGroups } from "../../../../../features/ui/uiSelectors";


// this components renders the individual product information that appears on the listing of products in the admin menu
const AdminDisplayProductCard = ({ product }) => {
    const isLoading = useSelector((state) => state.products.status === LOADING);
    const loginRedirect = useSelector(state => state.cart.status === LOGIN_ERROR || state.wishlist.status === LOGIN_ERROR);
    const location = useLocation();
    const navigate = useNavigate();
    const userGroups = useSelector(state => selectCustomerGroups(state));

    // navigates to the product edit page
    const handleDetailsClick = (productId) => {
        navigate(`/admin/products/${productId}`);
    };

    // if information is loading, display the loading component
    if (isLoading) {
        return <section className={`d-flex card col-12 p-2 justify-content-between shadow-sm`} >
            <Loading />
        </section>;
    }

    // if user is not logged in, redirect to the login and remove the current location. This way the user can not click the back button to go back to a protected route
    if (loginRedirect) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    // displays the product summary information comprising of image, title, prices per user group
    return (

        <section className={`d-flex card col-12 p-2 justify-content-between shadow-sm`} >
            <div className="d-flex flex-column justify-content-start card-body ">
                <div className="btn-group col-12" role="group" >
                    <div className="col-4 position-absolute z-2">
                        <AdminActionButton icon={faEdit} text={"Edit"} primary={false} onClick={() => handleDetailsClick(product?.id)} disabled={isLoading} />
                    </div>
                </div>
                <AdminProductImages productDetails={product} showAdditional={false} mainOnly={true} />
                <div className="d-flex">
                    <div className="d-flex col-12 flex-column justify-content-center align-items-center ">

                        <div className={`d-flex mb-1 fs-5 text-center text-uppercase text-decoration-none text-secondary `}>{product?.name}</div>

                    </div>
                </div>
            </div>

            <div className="d-flex flex-wrap justify-content-center">
                {
                    userGroups.map(userGroup => {
                        return <AdminPriceInformation price={product?.pricing?.find(price => price.userGroup.id === userGroup.id) || { userGroup: userGroup }} key={`${product.id}-${userGroup.id}`} />;
                    })
                }
            </div>

        </section>
    );

};

export default AdminDisplayProductCard;




