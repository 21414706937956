// external libraries
import { gql } from "@apollo/client";

// graphql query to get the checkout session id from stripe; if the session is present, the user is redirected to the stripe checkout
export const QUERY_STRIPE_CHECKOUT = gql`
  query GetCheckout($productData: [CartProductInputType]) {
    checkout(productData: $productData) {
    sessionId
    }
  }
`;

// graphql query to get the session details from stripe. this will be used to later fetch the order details from stripe
export const QUERY_STRIPE_SESSION = gql`
  query GetSessionDetails($sessionId: String) {
    sessionDetails(id: $sessionId)
  }
`;