// external librareis
import { productsAdapter } from "./productsSlice";
import { createSelector } from '@reduxjs/toolkit';

// default selectors offered by redux toolkit
export const {
    selectAll: selectAllProducts,
    selectById: selectProductById,
    selectIds: selectProductIds,
} = productsAdapter.getSelectors((state) => state.products);

// returns the category filters from the state
export const selectCategoryFilters = (state) => { return state.products.categoryFilters; };
// returns the error messages from the state in bullet points
export const selectErrors = createSelector(
    [(state) => state.products.error],
    (errors) => {
        if (errors && Array.isArray(errors)) {
            return errors.map(error => {
                return `<li>${error.message}</li>`;
            });

        }
    }
);

// returns a subset of product matching the category filters in the state
export const selectProductsByCategory = createSelector(
    [selectAllProducts, selectCategoryFilters],
    (products, categoryFilter) => products.filter((product) => {
        if (categoryFilter.length === 0) {
            return true;
        } else {
            // must filter product.category (array) and categoryFilter (array)
            return product.categories ? product.categories.some(category => categoryFilter.includes(category.id)) : false;
        }

    })
);
