// external libraries
import React, { useState, } from "react";

// components
import Error from "../Error";

// checkbox component that contains the commond functionality and features for all checkboxes used in the application
// checkbox is expecting input that is part of the /utilities/...fieldparams file where the checkbox details are defined. 
const Switch = ({ value, formUpdate, name, type, placeholder, validationValue, displayLabel, label, displayError, error, displayTooltip, tooltip }) => {
    const [isValid, setIsValid] = useState(false);

    const handleSwitchChange = (event) => {
        const fakeEvent = {
            currentTarget: {
                name: name,
                value: event.currentTarget.checked
            }
        };
        formUpdate(fakeEvent);
    };
    return (
        <div className="d-flex flex-wrap mb-3 form-check form-switch gap-2 ">
            <div className="col-12">
                <input type={type} className="d-flex form-check-input checkbox-colour" role="switch" name={name} id={name} onChange={handleSwitchChange} checked={value} />
                {displayLabel && <label className="d-flex flex-wrap form-check-label gap-2" htmlFor={name} name={name} >{label}</label>}
            </div>
        </div>

    );
};

export default Switch;