// external libraries
import React, { useState, } from "react";

// components
import Error from "../Error";

// checkbox component that contains the commond functionality and features for all checkboxes used in the application
// checkbox is expecting input that is part of the /utilities/...fieldparams file where the checkbox details are defined. 
const CheckBox = ({ value, formUpdate, name, type, placeholder, validationValue, displayLabel, label, displayError, error, displayTooltip, tooltip }) => {
    const [isValid, setIsValid] = useState(false);

    const handleCheckChange = (event) => {
        formUpdate(event);
        setIsValid(event.target.checked === validationValue);
    };
    return (
        <div className="d-flex flex-wrap mb-3 form-check gap-2 ">
            <div className="col-12">
                <input type="checkbox" className="d-flex form-check-input checkbox-colour" name={name} id={name} onChange={handleCheckChange} checked={value} />
                {displayLabel && <label className="d-flex flex-wrap form-check-label gap-2" htmlFor={name} name={name} >{label}</label>}
            </div>
            {!isValid && <Error message={error} />}
        </div>

    );
};

export default CheckBox;