// external libraries
import { gql } from '@apollo/client';

// query to update the wishlist
export const MUTATION_ADD_TO_WISHLIST = gql`
mutation UpdateWishlist($products: [WishlistProductInputType], $id: ID) {
  upsertWishlist(wishlistData: {products: $products}, id: $id) {
    wishlist {
      id
      products {
        id
        name
      }
    }
  }
}

`;