// external libraries
import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// components
import Loading from "../../../../common/Loading";
import DashboardProductCard from "../../Dashboard/Card";

// redux slice
import { LOGIN_ERROR } from "../../../../../features/status";

// this is the main component container that displays the products on the dashboard. It is used in all three dashboard pages
// - cart
// - wishlist
// - order summary
// - loading: boolean to determine if the global state is still loading
// - icon: the icon to be displayed on the title of the div
// - title: the title of the div
// - products: the products to be displayed
// - display: the display is an object that controls which components of the product card will be displayed; each page has different requirements
const ProductsContainer = ({ loading, icon, title, products, display }) => {
    const login_error = useSelector(state => state.cart.status === LOGIN_ERROR || state.wishlist.status === LOGIN_ERROR);
    const location = useLocation();

    // if there is a login error, redirect to the login page (this should also be handled by the protected route component)
    // TODO: investigate if this can be handled by the protected route component
    if (login_error) {
        return <Navigate to="/login" replace state={{ from: location.pathname }} />;
    }

    // if the global state is still loading, display the loading component
    if (loading) {
        return <Loading />;
    }

    // returns the product container component - this component is used to display the products on the dashboard and each dashboard page has different requirements
    return (
        <div className="border border-1 rounded shadow p-2 mb-2">
            <div className="d-flex col-12 align-items-center ">
                <h3 className="d-flex gap-2 mb-0">
                    <FontAwesomeIcon icon={icon} />
                    <span>{`${title} (${products.length} ${products.length === 1 ? "item" : "items"})`}</span>
                </h3>
            </div> {/* this is the title */}

            {products.map((product) => {
                return (
                    <Fragment key={`fragment-${product.id}`}>
                        <hr className="mb-4" key={`hr-${product.id}`} />
                        {<DashboardProductCard key={product.id} product={product} display={display} />}
                    </Fragment>
                );
            })}
        </div>

    );
};

export default ProductsContainer;