// external libraries
import React, {useEffect} from "react";
import { Link } from "react-router-dom";

// components

// the landing page of the application. It filters max 6 products to show as featured products
const NotFound = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<main className="d-flex flex-column">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>
            Sorry, the page you are looking
            for does not exist. <Link to="/" className="text-primary">Click here</Link> to go back to the home page.
        </p>
    </main>);
};

export default NotFound;

