// external libraries
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// components
import InputField from "../FormFields/InputField";
import CheckBox from "../FormFields/CheckBox";

// redux slice
import { LOGIN_USER } from "../../../features/user/userMutations";
import { fetchAdminUISettings, globalStatusReset } from "../../../features/ui/uiSlice";
import { cartReset } from "../../../features/cart/cartSlice";
import { wishlistReset } from "../../../features/wishlist/wishlistSlice";
import { ordersReset } from "../../../features/orders/ordersSlice";
import { stripeReset } from "../../../features/stripe/stripeSlice";
import { fetchUserData, userReset } from "../../../features/user/userSlice";
import { fetchProducts } from "../../../features/products/productsSlice";

// utilities
import auth from "../../../utils/auth";
import { emailFieldParams, passwordLoginFieldParams, checkboxAgreedFieldParams } from "../../../utils/fieldParameters";

// custom css
import './index.css';


// component holding all the fields and layout of the login form
// the component first clears the state and any local storage / session storage information and presents the user clean slate
// once the user is logged in, depending on whether the user is staff or not, additional information is fetched
// TODO: invesitgate usage of redux thunk to log in the user
const LoginForm = ({ redirectTo }) => {
    const [formState, setFormState] = useState({ email: "", password: "", agreed: false });
    const [loginError, setLoginError] = useState({ status: false, message: "" });
    const [login] = useMutation(LOGIN_USER);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // clears the existing state
    useEffect(() => {
        auth.logout();
        dispatch(globalStatusReset());
        dispatch(userReset());
        dispatch(cartReset());
        dispatch(wishlistReset());
        dispatch(ordersReset());
        dispatch(stripeReset());
    }, []);

    // updates the component's formSTate hoding the login information
    const handleFormUpdate = (event) => {
        const { name, type, value, checked } = event.target;
        setFormState({
            ...formState,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // uses graphql query to log in the user; if the user is staff, additional information is displayed
    // on error, the error message is displayed
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // user can not proceed without aggreeing to terms and conditions
        if (!formState.agreed) {
            setLoginError({
                status: true,
                message: "Please agree to the terms and conditions!"
            });
            return;
        }
        setLoading(true);
        // attempting login
        try {
            const mutationResponse = await login({
                variables: { email: formState.email, password: formState.password }
            });
            const token = mutationResponse.data?.loginUser?.token ?? null;
            if (token) {
                auth.login(token);
                dispatch(fetchUserData()).then((user) => {
                    navigate(redirectTo);
                    // must refetch products to get prices for the user groups (wholesaler, customer)
                    // staff users will get both customer and wholesaler prices
                    dispatch(fetchProducts());
                    if (user?.payload?.data?.userData?.isStaff) {
                        dispatch(fetchAdminUISettings());
                    }
                    setFormState({ email: "", password: "", agreed: false });
                });


            }

        } catch (error) {
            // displaying error on unsucessful login
            setLoginError({
                status: true,
                message: error.message
            });
            setLoading(false);
        }

    };

    // displaying the form fields needed for login. login fields are defined in /utils/formParameters.js
    return (
        <form className="d-flex flex-column col-12" onSubmit={handleFormSubmit}>
            <h5 className="fw-normal mb-3 pb-3 fs-3 text-primary" >Sign into your account</h5>
            {loginError.status && <div className="alert alert-danger">{loginError.message}</div>}
            <InputField value={formState.email} formUpdate={handleFormUpdate} {...emailFieldParams} />

            <InputField value={formState.password} formUpdate={handleFormUpdate} {...passwordLoginFieldParams} />

            <CheckBox value={formState.agreed} formUpdate={handleFormUpdate} {...checkboxAgreedFieldParams} />

            <div className="pt-1 mb-4">
                <button className="btn btn-outline-primary btn-lg btn-block" type="submit">{loading ? <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> : "Login"}</button>
            </div>

            <p className="mb-5 pb-lg-2" >Don't have an account? <Link to={"/register"} className="text-primary"
            >Register here</Link></p>

        </form>);
};

export default LoginForm;

