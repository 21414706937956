// external libraries
import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useLocation } from "react-router-dom";

// components
import ProductsContainer from "../../../components/customer/Products/Dashboard/List";
import Subtotals from "../../../components/customer/Dashboard/Subtotals";

// redux slice
import { SUCCESS, LOADING, LOGIN_ERROR } from "../../../features/status";
import { selectAllCartProductDetails } from "../../../features/cart/cartSelectors";

// customers cart page showing all products in user's cart
const CartPage = () => {
    // checks if products are loaded in the redux store; if not redirect away from cart
    const productsLoaded = useSelector(state => state.products.status === SUCCESS);
    // checks if user information is loading. if yes, display loading 
    const loading = useSelector((state) => state.user.status === LOADING);
    // checks if the user is logged in, if not redirect to login
    const loginError = useSelector((state) => state.user.status === LOGIN_ERROR || state.cart.status === LOGIN_ERROR || state.ui.globalStatus === LOGIN_ERROR);
    // gets all product details along with the quantity
    const productsInCart = useSelector((state) => selectAllCartProductDetails(state));
    // display settings for the products in the cart; this controls what is displayed in the cart
    const display = {
        quantity: {
            component: true,
            buttons: true,
            disabled: false
        },
        cart: {
            add: false,
            update: true
        },
        wishlist: true,
        remove: {
            cart: true,
            wishlist: false
        }
    };
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // if user not logged in, redirect to login
    if (loginError) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    // if products are not loaded, redirect to products page
    if (!productsLoaded) {
        return <Navigate to="/products" replace />;
    }

    // user's cart page showing all products in the cart
    return (
        <main className="d-flex flex-column">
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-start my-5 mx-2 gap-2 ">
                <div className="d-flex flex-column col-12 col-lg-8">
                    <ProductsContainer loading={loading} icon={faShoppingCart} title={"Shopping Cart"} products={productsInCart} display={display} />
                </div>
                <div className="d-flex flex-column col-12 col-lg-4">
                    <Subtotals />
                </div>
            </div>

        </main>
    );
};

export default CartPage;

