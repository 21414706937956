// external libraries
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// components
import InputField from "../../../../common/FormFields/InputField";
import InputDropdownField from "../../../../common/FormFields/InputDropdown";
import DropdownCheckboxField from "../../../../common/FormFields/DropdownCheckbox";
import AdminPriceInput from "../../Common/PriceInput";

// redux slice
import { LOGIN_ERROR } from "../../../../../features/status";
import { selectCustomerGroups } from "../../../../../features/ui/uiSelectors";

// utilities
import { productCategoryCheckboxFieldParams, productCategoryDropdownFieldParams, productNameFieldParams, productSKUFieldParams, productWeightScaleFieldParams, productWeightValueFieldParams, switchVisibilityFieldParams } from "../../../../../utils/fieldParameters";
import Switch from "../../../../common/FormFields/Switch";


// component that displays all input fields for product name, product categories, weight, and price
// the length, width, and height are work in progress
const AdminProductVisibility = ({ onChange, formState }) => {
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start mb-3">
            <h3 className="mb-1 text-secondary fs-4 mb-3">Product Visibility</h3>
            <Switch value={formState[0].isActive} formUpdate={onChange} {...switchVisibilityFieldParams} />
        </div>
    );
};


export default AdminProductVisibility;