// external libraries
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// redux slice
import { SUCCESS } from '../../../features/status';
import { selectUserIsStaff } from '../../../features/user/userSelectors';

// route only for customers - redirects staff to the admin page, and customers to the children components
const CustomerRoute = ({ children }) => {
  const status = useSelector(state => state.user.status);
  const userIsStaff = useSelector(state => selectUserIsStaff(state));
  let location = useLocation();

  if (status === SUCCESS) {
    return (!userIsStaff) ? children : <Navigate to="/admin" replace state={{ from: location }} />;
  }

  return (children);
};

export default CustomerRoute;
