// external libraries
import { gql } from '@apollo/client';

// query to get the description of the product using ChadGPT; since this doesn't update the state, it is in a separate file
export const QUERY_CHADGPT_ECOMMERCE = gql`
  query ChadGPTDescription($productData: ProductInputType!) {
    chadgptResponse(productData: $productData) {
      productName
      description
      keyFeatures
    }
  }
`;

export const QUERY_GET_STRIPE_PUBLIC_KEY = gql`
query GetStripePublicKey {
  stripePublicKey
}
`;