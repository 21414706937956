// external libraries
import React from "react";

// utilities
import { formatCurrency } from "../../../../../utils/numberFormats";


// the component displays the price in the currency selected
// furute release: the ability of the user to select currecny in which to view the products
// the price is complex object because of the following
// - in order to accommodate the future state, the price is an object containing currency information; default currenct is CAD (canadian dollar)
// - in order to accommodate more than one user groups, the price object contains the price information per user group
// - in order to accommodate regular and special (i.e. sale prices), the price object contains the regular and speical prices per user group
const AdminPriceInformation = ({ price }) => {
    // retrieves the user group and the different pricing options
    const { userGroup, regular, special } = price;

    // component renders the user group and the regular and speical (if special prices exits) prices
    return (
        <div className="d-flex flex-column mb-3 mx-1 p-3 justify-content-evenly align-items-center border rounded shadow ">
            <div className="text-secondary fs-5 mb-2">
                {userGroup?.name || "User Group"}
            </div>
            {special ?
                <div className={`d-flex text-decoration-line-through text-secondary fs-6 `}>
                    {formatCurrency(regular)}
                </div>
                : ''
            }
            <div className={`d-flex text-primary fs-4 `}>
                {formatCurrency(special ? special : regular )}
            </div>
        </div>
    );
};

export default AdminPriceInformation;