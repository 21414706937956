// external libraries
import React, { Fragment } from "react";

// custom css
import "./index.css";

// this is the common produc image component that displays the images in the product listing as well as the product page
// - productDetails: the details of the product that is selected by the user
// - showAdditional: boolean to determine if the additional images should be displayed
// - mainOnly: boolean to determine if only the main image should be displayed
const ProductImages = ({ productDetails, showAdditional = true, mainOnly = false }) => {
    const { images } = productDetails;
    const noImage = "/media/products/no-product-image.jpg";

    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-center align-items-center mb-3">
            <div id="productImageCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide col-12">
                {/* if mainOnly, that means the we only want to display one image
                // - the main image is selected
                // - if there is no main image, the first image in the array is selected
                // - if no image, then the no image is shown
                // - if errors loading the image, return the no image 
                // this is used for the product card in the product display page*/}
                <div className="carousel-inner ">
                    {mainOnly || images.length === 0 ? <div className="carousel-item active ">
                        <img src={images?.find(image => image.isMain)?.url || images?.[0]?.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex col-12 img-fluid" alt="..." />
                    </div>
                        :
                        // in the case of the individual product page, we want to display all the images
                        // same logic applies as above
                        <Fragment>
                            {images.map((image, index) => {
                                return (<div className={`carousel-item ${(image.isMain || images.length ===1 ) ? " active" : ""} `} key={index}>
                                    <img src={image.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex col-12 img-fluid" alt={`Product illustration ${index + 1}`} />
                                </div>);
                            })}
                        </Fragment>
                    }
                </div>
                {/* showAdditional is for displaying the additional images as thumbnails on the carousel so that the user can click on them and be redirected to the bigger version of the image
                - same display logic as above */}
                {showAdditional &&
                    <Fragment>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex carousel-indicators col-12 col-md-10 justify-content-start overflow-x-auto" style={{ height: "150px" }}>
                                {images.map((image, index) => {
                                    return (<button type="button" data-bs-target="#productImageCarousel" data-bs-slide-to={index} className="d-flex p-2 active h-auto w-auto" aria-current="true" aria-label="Slide 1" key={index}>
                                        <img src={image.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex h-100 img-fluid" alt={`Product illustration ${index + 1}`} />
                                    </button>);
                                })}
                            </div>
                        </div>
                        {/* carousel left and right buttons; they are not totally visible so they might be removed in future release */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#productImageCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#productImageCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default ProductImages;