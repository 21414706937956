// external libraries
import { useSelector } from "react-redux";

// components
import AccountMenu from "./Account";
import MainMenu from "./Main";
import SettingsMenu from "./Settings";
import AdminMenu from "./Admin";

// redux slice
import { selectUserIsStaff } from "../../../features/user/userSelectors";

// component handling the display of the menu buttons at the top right corner of the application
// the buttons vary doending on the type of user
const Menu = () => {
    const userIsStaff = useSelector(state => selectUserIsStaff(state));

    return (
        <div className="d-flex col-12 p-2 justify-content-end justify-content-sm-end align-items-center z-9 gap-2">
            {userIsStaff ?
                <>
                    <AccountMenu />
                    <AdminMenu />
                </> :
                <>
                    <AccountMenu />
                    <SettingsMenu />
                    <MainMenu />
                </>
            }

        </div>
    );
};

export default Menu;
