// external libraries
import React, {useEffect} from "react";

// components
import Disclaimer from "../../../../components/common/Disclaimer";

// disclaimer page to show the additional dislciamer information
const DisclaimerPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main className="d-flex flex-column">
            <Disclaimer />
        </main>
    );
};

export default DisclaimerPage;

