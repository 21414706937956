// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// redux slice
import { QUERY_PRODUCTS } from './productQueries';
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { UPSERT_PRODUCT } from './productMutations';

// apollo client
import { client } from '../../app/apollo';

// create the products adapter as per redux documentation
export const productsAdapter = createEntityAdapter();

// initial state for the products slice - 4 products must have ID; modified at is optional as I removed the sort comparer
const initialProducts = [{ "id": 1, "modifiedAt": Date.now().toLocaleString() },
{ "id": 2, "modifiedAt": Date.now().toLocaleString() },
{ "id": 3, "modifiedAt": Date.now().toLocaleString() },
{ "id": 4, "modifiedAt": Date.now().toLocaleString() }];

// initial state for the products slice
const initialState = productsAdapter.getInitialState({
    status: IDLE,
    error: null,
    categoryFilters: [],
});

// adding the initial products to the state. considering this is a entity adapter, the products are added to the entities via the setAll method
const populatedState = productsAdapter.setAll(initialState, initialProducts);

// async thunk to fetch products from the server
export const fetchProducts = createAsyncThunk('products/fetchProducts',
    async () => {
        try {
            const response = await client.query({
                query: QUERY_PRODUCTS,
                variables: {},
                fetchPolicy: 'network-only'
            });
            return response;
        } catch (error) {
            console.log(error);
        }
    });

// async thunk to upsert a product to the server
export const upsertProduct = createAsyncThunk(
    'products/upsertProduct',
    async ({ id = null, product_data }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: UPSERT_PRODUCT,
                variables: {
                    id: id,
                    productData: product_data
                }
            });
            return response;
        } catch (error) {
            console.log(error);
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    }
);

// creating the products slice as per redux documentation
const productsSlice = createSlice({
    name: 'products',
    initialState: populatedState,
    reducers: {
        filterReset(state, action) {
            state.categoryFilters = [];
        },
        filterUpdated(state, action) {
            const filter = action.payload;
            if (state.categoryFilters.indexOf(filter) > -1) {
                state.categoryFilters = state.categoryFilters.filter(category => category !== filter);
            } else {
                state.categoryFilters.push(filter);
            }
        },
        errorReset(state, action) {
            state.error = null;
            state.status = IDLE;
        }
    },
    extraReducers(builder) {
        builder
            // when the fetch products async thunk is pending, the status is set to loading
            // when the fetch products async thunk is fulfilled, the status is set to success and the products are added to the state
            // when the fetch products async thunk is rejected, the status is set to failed and the error is set to the error message
            .addCase(fetchProducts.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;

                // Add any fetched products to the array
                productsAdapter.removeAll(state);
                productsAdapter.upsertMany(state, (action?.payload?.data?.products || []));
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload.error;
            })
            // when the upsert product async thunk is pending, the status is set to loading
            // when the upsert product async thunk is fulfilled, the status is set to success and the product is added to or updated in the state
            // when the upsert product async thunk is rejected, the status is set to either login_error or failed and the error is set to the error message
            .addCase(upsertProduct.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;
                productsAdapter.upsertOne(state, (action?.payload?.data?.upsertProduct?.product));
            })
            .addCase(upsertProduct.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return (
                    login_error ?
                        {
                            ...initialState,
                            status: LOGIN_ERROR,
                            error: LOGIN_ERROR
                        } : {
                            ...state,
                            status: FAILED,
                            error: action.payload.errors
                        }
                    
                );
    })
    .addCase(upsertProduct.pending, (state, action) => {
        state.status = LOADING;
        state.error = null;

    });
    },
});

// exporting the products slice actions
export const { filterReset, filterUpdated, errorReset } = productsSlice.actions;

// exporting the products slice reducer
export default productsSlice.reducer;

