// external libraries
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// redux slice
import { selectCategoryFilters } from "../../../../features/products/productsSelectors";
import { filterUpdated, filterReset } from "../../../../features/products/productsSlice";


// utilities
import { handleEventWithStopPropagation } from "../../../../utils/ui";


// component creating UL of the main menu based on the categories in the database. The UL element is used by the base menu component to render it to the user
const MainOverlay = ({ menu }) => {
    const dispatch = useDispatch();
    const currentFilters = useSelector((state) => selectCategoryFilters(state));

    // clicing on a category, updates the global state with the category selected. products are then filtered based on the category
    const handleUpdateFilters = (event) => {
        dispatch(filterUpdated(event.currentTarget.dataset.category));
    };

    // reset of the category filter from the main menu so that all product are displayed
    const handleResetFilters = () => {
        dispatch(filterReset());
    };

    // returns UL element of the main menu. There's one hardcoded "category" of all products meant to remove the filter element
    return (
        <ul className="d-flex dropdown-menu border-0 bg-transparent px-2 flex-column fs-4 list-group ">
            <li className="d-flex list-group-item justify-content-end border-0 bg-transparent" key={"all"}>
                <Link to="/products" className="text-decoration-none">
                    <button className="btn fs-4 text-primary d-flex gap-2" type="button" data-bs-toggle="" aria-expanded="false" onClick={handleResetFilters}>
                        <span>All Products</span>
                    </button>
                </Link>
            </li>
            {/* creates the parent/child category structure where children are dropdown boxes of the parent category. 
                checkbox in front of the category is created in order to alert the user which categories have been selected */}
            {menu.map((item, index) => {
                return (
                    <li className="d-flex list-group-item justify-content-end border-0 bg-transparent" key={index}>
                        {
                            (item.children.length > 0) ?
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle fs-4 text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleEventWithStopPropagation}>
                                        {item.category.name}
                                    </button>
                                    <ul className="dropdown-menu ">
                                        {item.children.map((childCategory, index) =>
                                            <li className="d-flex list-group-item justify-content-end border-0 bg-transparent" key={index}>
                                                <Link to="/products" className="text-decoration-none">
                                                    <button className="btn dropdown-item col-12 fs-4 text-primary d-flex justify-content-endgit gap-2" type="button" data-category={childCategory.category.id} onClick={handleUpdateFilters}>
                                                        {currentFilters.includes(childCategory.category.id) && <span>
                                                            <FontAwesomeIcon icon={faCheckCircle} className="text-dark" />
                                                        </span>
                                                        }
                                                        <span>{childCategory.category.name}</span>
                                                    </button>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                :
                                <Link to="/products" className="text-decoration-none">
                                    <button className="btn fs-4 text-primary d-flex gap-2" type="button" data-bs-toggle="" aria-expanded="false" data-category={item.category.id} onClick={handleUpdateFilters}>
                                        {currentFilters.includes(item.category.id) && <span>
                                            <FontAwesomeIcon icon={faCheckCircle} className="text-dark" />
                                        </span>
                                        }
                                        <span>{item.category.name}</span>
                                    </button>
                                </Link>
                        }
                    </li>
                );
            })}
        </ul>
    );
};

export default MainOverlay;