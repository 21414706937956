// external libraries
import { configureStore } from '@reduxjs/toolkit';

// redux slices
import productsReducer from '../features/products/productsSlice';
import userGroupSlice from '../features/user/userSlice';
import uiSlice from '../features/ui/uiSlice';
import cartSlice from '../features/cart/cartSlice';
import wishlistSlice from '../features/wishlist/wishlistSlice';
import ordersSlice from '../features/orders/ordersSlice';
import stripeSlice from '../features/stripe/stripeSlice';

// setting up redux store with the various slices
export const store = configureStore({
    reducer: {
        products: productsReducer,
        user: userGroupSlice,
        ui: uiSlice,
        cart: cartSlice,
        wishlist: wishlistSlice,
        orders: ordersSlice,
        stripe: stripeSlice
    },
});
