// exgternal libraries
import React, { useEffect } from "react";
import ReactQuill from "react-quill-new";
import "../../../../../../node_modules/react-quill-new/dist/quill.snow.css";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";
import { useLazyQuery } from "@apollo/client";

// components
import AdminActionButton from "../../../ActionButton";

// utilities
import { productDescriptionFieldParams } from "../../../../../utils/fieldParameters";
import { QUERY_CHADGPT_ECOMMERCE } from "../../../../../utils/queries";



// renders the component for product description
// this component uses react-quill as the rich text editor; however, it has been found that there is an error on how react-quill is being initialised and chrome dev tools alwawys displays an error WIP to fix it
// the component also provides openAI integration for the chatgpt to generate a descirption for the product automatically
const ProductDescription = ({ description, onChange, formState }) => {
    // using lazyquery so that we trigger the graphql query when user clicks on the buttons
    const [fetchDescription, { loading, data }] = useLazyQuery(QUERY_CHADGPT_ECOMMERCE);
   
    // handles the call top OpenAI. the call is a django query
    const handleOpenAIResponse = async () => {
        try {
            await fetchDescription({
                variables: { productData: formState[0] },
            });

        } catch (error) {
            console.log(error);
        }
    };

    // once we get data back from chatgpt, we process the data and update the description. the openAI data is structured into
    //  {
    //      product_name: product name as provided by customer
    //      descritpion: description text
    //      key_features: [ string ] string array with summary of features that is turned into <ul><li> and added to the description
    //  }
    useEffect(() => {
        if (data) {
            let chadGPTDescription = "";
            if (data.chadgptResponse.description) {
                chadGPTDescription = `<p>${data.chadgptResponse.description}</p > `;
            }
            if (data.chadgptResponse.keyFeatures) {
                chadGPTDescription += `<p></p><p>Key Features</p > `;
                chadGPTDescription += `<ul>`;
                data.chadgptResponse.keyFeatures.map(feature => {
                    chadGPTDescription += `<li>${feature}</li>`;
                });
                chadGPTDescription += `</ul>`;
            }
            changeDescription(chadGPTDescription);
        }
    }, [data]);

    // handling the changes when the user manually inputs a description
    const changeDescription = (text) => {
        onChange({ currentTarget: { name: productDescriptionFieldParams.name, value: text } });
    };

    // displays the component rendering openAI button and ReactQuill.
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start mb-3">
            <div id="description" className="mb-3">
                <h3 className="mb-1 text-secondary fs-4 mb-3">Description</h3>
                <div className="d-flex flex-row flex-wrap col-3">
                    <AdminActionButton icon={faUserGear} text={"Get Chad's description"} primary={true} onClick={handleOpenAIResponse} disabled={loading} loadEvent={loading} />
                </div>
                <ReactQuill
                    value={description}
                    name={productDescriptionFieldParams.name}
                    id={productDescriptionFieldParams.name}
                    onChange={(text) => changeDescription(text)}
                    theme="snow"
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'align': [] }],
                            ['link',],
                        ],
                    }}

                    placeholder={productDescriptionFieldParams.placeholder}
                />
            </div>
        </div>
    );
};

export default ProductDescription;