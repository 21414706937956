// external libraries
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";

// compoenents
import BaseOverlay from "./overlay";

// redux slice
import { selectMenuOpened } from "../../../../features/ui/uiSelectors";
import { toggleMenuOpened } from "../../../../features/ui/uiSlice";

// custom css
import "../index.css";


// Base menu component that has all functionality for menu. Accepts icon from the "parent" menu and display that icon or bars is none supplied
// inputs are the menu icon, loading state, the overlay, and closeOnClick - true/false showing whether the menu closes when user clicks on any of the menu options
// closeOnClick is set to false for the settings menu as the user may wish to change more than one setting before closing the menu
const BaseMenu = ({ icon, loading, overlay, closeOnClick }) => {
    const [baseMenuOpened, setBaseMenuOpened] = useState(false);
    const [baseMenuSpinIcon, setBaseMenuSpinIcon] = useState(false);
    const [baseMenuShowOverlay, setBaseMenuShowOverlay] = useState(false);
    const menuIsOpen = useSelector(state => selectMenuOpened(state));
    const dispatch = useDispatch();

    // handles the menuclick that spins the menu icon and opens the supplied overlay
    const handleMenuClick = () => {
        if (loading) return;
        if (menuIsOpen === baseMenuOpened) {
            setBaseMenuSpinIcon(true);
            setBaseMenuShowOverlay(!baseMenuShowOverlay);
            setTimeout(() => {
                setBaseMenuOpened(!baseMenuOpened);
                dispatch(toggleMenuOpened(baseMenuOpened));
                setBaseMenuSpinIcon(false);

            }, 250);
        } else {
            // if user tries to open a menu while a menu is already open, user gets an error message
            alert("a menu is open");
        }
    };

    return (
        <div className="p-2 ">
            <div className="d-flex p-2 justify-content-center align-items-center">
                <button className="d-flex btn p-0 position-fixed z-3 bg-white border border-1 shadow-sm">
                    {loading ? <FontAwesomeIcon
                        icon={faSpinner}
                        className="p-2 fa-spin "
                        role="status"
                    /> :
                        <FontAwesomeIcon
                            icon={baseMenuOpened ? faX : (icon || faBars)}
                            className={`fade-spin p-2 ${baseMenuSpinIcon ? 'fade-out spin' : ''} `}
                            onClick={handleMenuClick}
                        />}
                </button>
            </div>
            <div >
                <BaseOverlay overlayAction={baseMenuShowOverlay} overlay={overlay} handleMenuClick={handleMenuClick} closeOnClick={closeOnClick} />
            </div>
        </div>
    );
};

export default BaseMenu;

