// external librareis
import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faSave } from "@fortawesome/free-solid-svg-icons";

// components
import AdminProductInformation from "../../../components/admin/Products/Page/Info";
import AdminProductDescription from "../../../components/admin/Products/Page/Description";
import AdminProductImagesInput from "../../../components/admin/Products/Common/ImagesInput";
import Error from "../../../components/common/FormFields/Error";
import AdminProductVisibility from "../../../components/admin/Products/Page/Visibility";
import AdminActionButton from "../../../components/admin/ActionButton";

// redux slice
import { selectErrors, selectProductById } from "../../../features/products/productsSelectors";
import { errorReset, upsertProduct } from "../../../features/products/productsSlice";
import { FAILED, LOADING, LOGIN_ERROR } from "../../../features/status";
import { selectCustomerGroups } from "../../../features/ui/uiSelectors";

// AdminProduct product page - this is the page where the admin can edit the product details
const AdminProduct = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const loginError = useSelector(state => state.products.status === LOGIN_ERROR);
    const saveError = useSelector(state => state.products.status === FAILED);
    const saveErrorMessages = useSelector(state => selectErrors(state));
    const productDetails = useSelector(state => selectProductById(state, id));
    // when product is updates, this ensures that save button icon is spinning. Potentially could be used to disable the input fields
    const loading = useSelector(state => state.products.status === LOADING);
    const userGroups = useSelector(state => selectCustomerGroups(state));
    // the formState is sent to the back end to update the product
    const formState = useState(
        {
            name: productDetails?.name || "",
            categories: productDetails?.categories.map(category => category.id) || [],
            sku: productDetails?.sku || "",
            weight: productDetails?.weight || {},
            length: productDetails?.length || {},
            width: productDetails?.width || {},
            height: productDetails?.height || {},
            images: productDetails?.images || [],
            // dimensions: productDetails?.dimensions || {},
            pricing: productDetails?.pricing || [...userGroups.map(userGroup => {
                return { userGroup: { id: userGroup.id, name: userGroup.name }, regular: null, special: null };
            })],
            description: productDetails?.description || "",
            isActive: productDetails?.active || true,
            files: []
        }
    );

    // clear any product errors from any previous operations
    useEffect(() => {
        dispatch(errorReset());
        window.scrollTo(0, 0);
    }, []);

    // if user is not logged in, redirect to login
    if (loginError) {
        return <Navigate to="/login" replace />;
    }

    // if product is not found, redirect to the products page
    if (!productDetails && id) {
        return <Navigate to="/admin/products"  replace />;
    }

    // handle form submit
    const handleFormSubmit = (e) => {
        e.preventDefault();
        dispatch(upsertProduct({ id: id || null, product_data: formState[0] }));
    };

    // handle updates to form fields
    const handleFormUpdate = (event) => {
        const { name, value } = event.currentTarget;
        formState[1]({
            ...formState[0],
            [name]: value,
        });
    };

    // product edit page layout
    return (
        <form className="d-flex flex-column mb-4" >
            <div className="d-flex flex-column flex-md-row flex-md-wrap justify-content-start align-items-start mx-2 border rounded shadow">
                <div className="d-flex px-3 py-2 justify-content-start">
                    <div className="d-flex flex-wrap position-fixed z-3">
                        <AdminActionButton icon={faSave} text={"Save"} primary={true} onClick={(event) => handleFormSubmit(event)} disabled={loading} loadEvent={loading} />
                        {saveError && <Error  message={`Could not save product information! Please check the error below\n${saveErrorMessages}`} />}
                    </div>
                </div>

                <div className="d-flex flex-wrap col-12 ">
                    <div className="d-flex col-12 col-md-6 mb-3">
                        <AdminProductImagesInput productDetails={productDetails} onChange={(event) => handleFormUpdate(event)} formState={formState} />

                    </div>
                    <div className="d-flex flex-wrap col-12 col-md-6 mb-3">
                        <AdminProductVisibility productDetails={productDetails} onChange={(event) => handleFormUpdate(event)} formState={formState} />
                        <AdminProductInformation productDetails={productDetails} onChange={(event) => handleFormUpdate(event)} formState={formState} />
                    </div>
                </div>
                <div className="d-flex  col-12 ">
                    <AdminProductDescription description={formState?.[0]?.description} onChange={(event) => handleFormUpdate(event)} formState={formState} />

                </div>

            </div>
        </form>);

};

export default AdminProduct;