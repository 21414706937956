// external libraries
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// common loading component showing a spinning spinner
const Loading = () => {
    return (
        <div className="border border-1 rounded shadow p-2 mb-2">
            <div className="d-flex col-12 align-items-center">
                <h3 className="d-flex gap-2 mb-0">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    <span>Loading</span>
                </h3>
            </div>
        </div>
    );
};

export default Loading;