// external libraries
import React from "react";
import { Link } from "react-router-dom";

// displayes the two logo images 
// - one with the logo
// - second with the tag line. this is also hidden when going below bootstraps md breakpoint
const Logo = () => {
    return (
        <div className="d-flex col-12 p-2 justify-content-start align-items-center " >
            <Link to={"/"} className="d-flex gap-2" >
                <img style={{ maxHeight: "100px", width: "100px" }} src={"/media/non-products/logos/KOKABORA_transparent.svg"} className="d-flex " alt="company logo" id="logo-img"/>
                <img style={{ height: "100px" }} src={"/media/non-products/logos/KOKABORA_transparent_name.svg"} className="d-none d-md-flex img-fluid" alt="company name" id="logo-text"/>
            </Link>
        </div>

    );
};

export default Logo;

