// external libraries
import React from "react";
import { faGears } from '@fortawesome/free-solid-svg-icons';

// components
import BaseMenu from "../Base";
import AdminOverlay from "./overlay";


// admin main menu options 
// currently only products is shown, but it can be expanded to view all orders placed by all users; updates to categories, updates to user groups etc...
// another potential is to make it a database driven menu
const adminMenu = [
    {
        "menu": "Products",
        "url": "/admin"
    },
];

// creates a custom AccountOverlay of the account menus that is supplied to the BaseMenu utilizing base menu's features for all menus (i.e. button positioning, buton spin on click, etc)
const AdminMenu = () => {
    return <BaseMenu icon={faGears} loading={false} overlay={<AdminOverlay menu={adminMenu} />} />;
};

export default AdminMenu;

