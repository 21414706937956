// external libraries
import React from "react";

// component that displays the product description. this is only needed on the individual product page
const ProductDescription = ({productDetails}) => {
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start mb-3">
            <div id="description" className="mb-3">
                <h3 className="mb-1 text-secondary fs-4 mb-3">Description</h3>
                {/* the description is saved as HTML format and so needs to be converted to something that react understands */}
                <div className="d-flex flex-column col-12 fs-5" dangerouslySetInnerHTML={{ __html: productDetails.description}}></div>
            </div>
        </div>       
    );
};

export default ProductDescription;