// external libraries
import { createSelector } from "@reduxjs/toolkit";

// defining selectors based on the state
// selects the menu status from the state
export const selectMenuOpened = (state) => state.ui.menuOpened;
// selects the main menu categories from the state
export const selectMenuCategories = (state) => state.ui.mainMenu;
// selects the menu status from the state - status is when slice is updated from info in the back end
export const selectMenuStatus = (state) => state.ui.menuStatus;
// selects the user groups from the state
export const selectCustomerGroups = (state) => state.ui.userGroups;
// select the categires from the state
export const selectCategories = (state) => state.ui.categories;
// selects the lengths from the state
export const selectLength = (state) => state.ui.length;
// selects the weights from the state (future development)
export const selectWeight = (state) => state.ui.weight;
// selects all the measurements from the state and returns an object
export const selectMeasurements = createSelector([selectWeight, selectLength], (weight, length) => {
    return {
        length: length,
        weight: weight
    };
});
