// external libraries
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

// redux slice
import { LOADING } from "../../../features/status";


// action button component that is displayed on the product card
// - icon: the icon to be displayed on the button
// - text: any text to be displayed next to the icon
// - onClick: the function to be called when the button is clicked
// - primary: boolean to determine if the button is a primary button and update the CSS
// - disabled: boolean to determine if the button should be disabled disabled
// this is a prime candidate for a reusable component throughout the entire application not just the customer product display page
// TODO: investigate making this a reusable component
const ActionButton = ({icon, text, onClick, primary=false, disabled=false}) => {
    const [buttonClicked, setButtonClicked] = useState(false);
    const loading = useSelector(state => state.cart.status === LOADING || state.wishlist.status === LOADING);

    // contains the functionality when a button is clicked (i.e. icon is changed to a spinner and it spins and temporarily disables the button) 
    const clickButton = (event) => {
        event.preventDefault();
        setButtonClicked(true);
    };

    // if the loading status changes, reset the buttonClicked state
    if (!loading && buttonClicked) {
        setButtonClicked(false);
    }
    
    // returns the button with the icon and text (text is optional)
    return (
        <div className="d-flex flex-row col-12 "> {/* this is the product action buttons */}
        <button className={`btn ${primary ? "btn-primary" : "btn-outline-primary"} mb-2 gap-2 col-12 d-flex justify-content-center align-items-center`} disabled={loading || buttonClicked || disabled} onClick={(event)=>{
            clickButton(event);
            onClick();
        }}  >
            <FontAwesomeIcon icon={buttonClicked ? faSpinner : icon}  className={`${buttonClicked ? "fa-spin" : ""} d-flex`} />
                {text && <span className="d-flex">{text}</span>}
        </button>
    </div>
    );

};

export default ActionButton;