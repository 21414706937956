// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// redux slice
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { MUTATION_CREATE_ORDER } from './ordersMutations';
import { fetchUserData } from '../user/userSlice';


// apollo client
import { client } from '../../app/apollo';

// creating the orders adapter as per redux documentation
// using the sortComparer to sort the orders based on the createdAt field
export const ordersAdapter = createEntityAdapter({
    sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

// initial state fot the orders slice
const initialState = ordersAdapter.getInitialState({
    status: IDLE,
    error: null,
    selectedOrder: ""
});


// async thunk to create order after the user has paid through stripe
export const createOrder = createAsyncThunk(
    'orders/createOrder',
    async (orderData, { rejectWithValue }
    ) => {
        try {
            const response = await client.mutate({
                mutation: MUTATION_CREATE_ORDER,
                variables: { products: orderData.products, sessionId: orderData.session_id },
                fetchPolicy: 'network-only'
            });
            if (!response?.data?.upsertOrder?.order) {
                return rejectWithValue({ "login_error": true });
            }

            return response;
        } catch (error) {
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });

// creating the orders slice as oer redux documentation
const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        ordersReset(state, action) {
            return initialState;
        },
        // in the order summary page, when the user clicks on an order, the order is selected and updated in the state
        orderSelect(state, action) {
            state.selectedOrder = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            // after fetchUserData is successful, each component goes into it's own slice. the cart goes into the cart slice
            // if the fetchUserData is rejected, the intital state is returned
            .addCase(fetchUserData.fulfilled, (state, action) => {
                ordersAdapter.removeAll(state);
                ordersAdapter.upsertMany(state, action?.payload?.data?.orders || []);
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                return {...initialState};
            })
            // creating an order; the prerequisite is that the user must have paid through the stripe page
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and the order is added to the slice
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(createOrder.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;
                ordersAdapter.upsertOne(state, action?.payload?.data?.upsertOrder?.order || []);
            })
            .addCase(createOrder.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {...initialState, 
                    status: login_error ? LOGIN_ERROR : FAILED,
                    erorr: login_error ? LOGIN_ERROR : FAILED
                };
            });
    },
});

// exporting the orders slice actions
export const { ordersReset, orderSelect } = ordersSlice.actions;

// exporting the orders reducer
export default ordersSlice.reducer;

