// external libraries
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";

// components
import Loading from "../../../common/Loading";
import Disclaimer from "../../../common/Disclaimer";

// redux slice
import { selectAllCartProducts, selectCartProductSubtotal } from "../../../../features/cart/cartSelectors";
import { QUERY_STRIPE_CHECKOUT } from "../../../../features/stripe/stripeQueries";
import { LOADING } from "../../../../features/status";

// utilities
import { formatCurrency } from "../../../../utils/numberFormats";
import { QUERY_GET_STRIPE_PUBLIC_KEY } from "../../../../utils/queries";


// component that displays the subtotals of the cart
const Subtotals = () => {
    const [stripe, setStripe] = useState(null);
    const loading = useSelector((state) => state.products.status === LOADING || state.user.status === LOADING);
    const cartUpdating = useSelector((state) => state.cart.status === LOADING);
    const productsInCart = useSelector((state) => selectAllCartProducts(state));
    const productsSubTotal = useSelector((state) => selectCartProductSubtotal(state));
    // currently shipping is set to $0 for simplicity sake. Potential for integration wish shipping companies to get real time shipping costs
    const shippingSubTotal = 0;
    const grandTotal = { ...productsSubTotal, value: productsSubTotal.value + shippingSubTotal };
    const navigate = useNavigate();
    const [getCheckout, { data, error }] = useLazyQuery(QUERY_STRIPE_CHECKOUT);
    const [checkoutButtonClicked, setCheckoutButtonClicked] = useState(false);
    const { data: stripePublicKeyQuery } = useQuery(QUERY_GET_STRIPE_PUBLIC_KEY);

    // handles the continue shopping button
    const handleContinueShopping = () => {
        navigate("/products");
    };

    // handles the checkout button - makes a call to the stripe api query in django to create a session
    const handleCheckout = async () => {
        setCheckoutButtonClicked(true);
        const productData = productsInCart.map(({ __typename, ...rest }) => rest);
        // check to see if stripe has been initialized
        if (stripe) {
            await getCheckout({ variables: { productData: productData } });
        }

    };

    // if the data is returned from the stripe API, redirect to the stripe checkout page
    // otherwise redirect to login
    useEffect(() => {
        const proceedToCheckout = async () => {
            if (data?.checkout?.sessionId) {
                const { sessionId } = data.checkout;
                stripe.redirectToCheckout({
                    sessionId
                });
            } else {
                navigate("/login");
            }

        };
        // only proceed to checkout when the data is returned from the stripe API
        if (data) {
            proceedToCheckout();
        }
    }, [data, error, navigate]);


    useEffect(() => {
        const loadStripeInstance = async () => {
            const stripeInstance = await loadStripe(stripePublicKeyQuery?.stripePublicKey);
            setStripe(stripeInstance);
        };

        if (stripePublicKeyQuery?.stripePublicKey) {
            loadStripeInstance();
        }
    }, [stripePublicKeyQuery]);

    // if the global state is being updated, display the loading component
    if (loading) {
        return <Loading />;
    }

    // returns the subtotals component - the component encompasses the entire div, the title, the subtotals, and the buttons
    // returns the disclaimer component to ensure the client is aware this is a test site
    return (
        <Fragment >
            <div className="border border-1 rounded shadow p-2 mb-2">
                <div className="d-flex col-12 align-items-center">
                    <h3 className="d-flex gap-2 mb-0">
                        <FontAwesomeIcon icon={faSackDollar} />
                        <span>Subtotals</span>
                    </h3>
                </div> {/* this is the title */}
                <hr className="mb-4" />
                <div className="d-flex col-12 justify-content-between">
                    <span className="d-flex"><h5>Subtotals</h5></span>
                    <span className="d-flex"><h5>{formatCurrency(productsSubTotal)}</h5></span>
                </div> {/* this is the item subtotal */}
                <div className="d-flex col-12 justify-content-between">
                    <span className="d-flex"><h5>Shipping</h5></span>
                    <span className="d-flex"><h5>FREE</h5></span>
                </div> {/* this is the item subtotal */}
                <hr className="mb-4" />
                <div className="d-flex col-12 justify-content-between">
                    <span className="d-flex"><h4>Total</h4></span>
                    <span className="d-flex"><h4>{formatCurrency(grandTotal)}</h4></span>
                </div> {/* this is the item subtotal */}
                <div className="d-flex col-12 justify-content-between mt-4">
                    <button className="btn btn-outline-primary" disabled={loading} onClick={handleContinueShopping}>Continue Shopping</button>
                    <button className="btn btn-primary" disabled={cartUpdating || productsInCart.length === 0 || !stripe} onClick={handleCheckout}>{
                        checkoutButtonClicked ? <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> : <span>Checkout</span>
                    }
                    </button>
                </div> {/* this is the item subtotal */}

            </div>
            <div className="border border-1 rounded shadow p-2 mb-2">
                <Disclaimer />
            </div>
        </Fragment>
    );
};

export default Subtotals;