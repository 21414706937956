// external libraries
import { Link } from "react-router-dom";


// file containing the input field definitions of the input fields used in the application

export const emailFieldParams = {   
    name:"email",
    type:"email",
    placeholder:"test@example.com",
    validationRegex:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    displayLabel:true,
    label:"Email address",
    displayError:true,
    error:"Invalid email address",
    displayTooltip:false,
    tooltip:""
};

export const firstNameFieldParams = {   
    name:"first_name",
    type:"input",
    placeholder:"John",
    validationRegex:/^[A-Za-z]+$/,
    displayLabel:true,
    label:"First Name",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:""
};

export const lastNameFieldParams = {   
    name:"last_name",
    type:"input",
    placeholder:"Doe",
    validationRegex:/^[A-Za-z]+$/,
    displayLabel:true,
    label:"Last Name",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:""
};

export const passwordFieldParams = {   
    name:"password",
    type:"password",
    placeholder:"********",
    validationRegex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
    displayLabel:true,
    label:"Password",
    displayError:true,
    error:"Invalid password",
    displayTooltip:false,
    tooltip:"Password must contain a minimum of:<ul class=\"m-0\"><li>8 characters</li><li>one uppercase letter</li><li>one lowercase letter</li><li>one digit</li><li>one special character</li></ul>"
};

export const passwordConfirmationFieldParams = {   
    name:"confirmation",
    type:"password",
    placeholder:"********",
    validationRegex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
    displayLabel:true,
    label:"Password confirmation",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:"Must be the same as the password!"
};

export const checkboxAgreedFieldParams = {   
    name:"agreed",
    type:"checkbox",
    placeholder:"",
    validationValue:true,
    displayLabel:true,
    label: <span className="d-flex flex-row flex-wrap gap-0">I agree to the&nbsp;<Link to={"/terms"} className="d-flex text-primary">Terms of Use</Link>,&nbsp;<Link to={"/privacy"} className="d-flex text-primary">Privacy Policy</Link>,&nbsp;and&nbsp;<Link to={"/disclaimer"} className="d-flex text-primary">Disclaimer</Link></span>,
    displayError:true,
    error:"You must agree to the terms of use, privacy policy, and disclaimer!",
    displayTooltip:false,
    tooltip:""
};

export const passwordLoginFieldParams = {   
    name:"password",
    type:"password",
    placeholder:"********",
    validationRegex:/^.*$/,
    displayLabel:true,
    label:"Password",
    displayError:false,
    error:"",
    displayTooltip:false,
    tooltip:""
};

export const quantityFieldParams = {
    name: "quantity",
    type: "number",
    placeholder: "",
    validationRegex: /^[1-9]\d*$/,
    displayLabel: false,
    label: "Quantity",
    displayError: false,
    error: "",
    displayTooltip: false,
    tooltip: ""
};

export const productNameFieldParams = {   
    name:"name",
    type:"input",
    placeholder:"Product name",
    validationRegex:/^[A-Za-z -]+$/,
    displayLabel:true,
    label:"Product Name",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:""
};

export const productSKUFieldParams = {   
    name:"sku",
    type:"input",
    placeholder:"Inventory Number",
    validationRegex:/^[A-Za-z_-]+$/,
    displayLabel:true,
    label:"Product SKU",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:""
};

export const productWeightValueFieldParams = {   
    name:"weight-value",
    type:"number",
    placeholder:"Weight",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel:true,
    label:"Product Weight",
    displayError:true,
    error:"Invalid input",
    displayTooltip:false,
    tooltip:""
};

export const productWeightScaleFieldParams = {
    name: "weight-scale-code",
    type: "button",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: false,
    label: "Product scale",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productLengthValueFieldParams = {
    name: "length-value",
    type: "number",
    placeholder: "Length",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel: true,
    label: "Product Length",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productLengthScaleFieldParams = {
    name: "length-scale-code",
    type: "button",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: false,
    label: "Product length scale",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productWidthValueFieldParams = {
    name: "width-value",
    type: "number",
    placeholder: "Width",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel: true,
    label: "Product Width",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productWidthScaleFieldParams = {
    name: "width-scale-code",
    type: "button",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: false,
    label: "Product width scale",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productHeightValueFieldParams = {
    name: "height-value",
    type: "number",
    placeholder: "Height",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel: true,
    label: "Product Height",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productHeightScaleFieldParams = {
    name: "height-scale-code",
    type: "button",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: false,
    label: "Product height scale",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productCategoryDropdownFieldParams = {
    name: "category",
    type: "button",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: true,
    label: "Product Categories",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productCategoryCheckboxFieldParams = {
    name: "categories",
    type: "checkbox",
    placeholder: "",
    validationRegex: /^.{1,}$/,
    displayLabel: true,
    label: "Product Category",
    displayError: false,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productRegularPriceFieldParams = {
    name: "regularPrice",
    type: "number",
    placeholder: "Price",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel: true,
    label: "Regular Price",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productSpecialPriceFieldParams = {
    name: "specialPrice",
    type: "number",
    placeholder: "Price",
    validationRegex: /^(\d+(\.\d*)?|\.\d+)$/,
    displayLabel: true,
    label: "Special Price",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productSpecialFromFieldParams = {
    name: "specialFrom",
    type: "datetime-local",
    placeholder: '',
    validationRegex: /^[\w\W]*$/,
    displayLabel: true,
    label: "Special From Date",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};


export const productSpecialToFieldParams = {
    name: "specialTo",
    type: "datetime-local",
    placeholder: '',
    validationRegex: /^[\w\W]*$/,
    displayLabel: true,
    label: "Special To Date",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const productDescriptionFieldParams = {
    name: "description",
    type: "textarea",
    placeholder: 'Enter product description here',
    validationRegex: /^[\s\S]{15,}$/,
    displayLabel: true,
    label: "Product Description",
    displayError: true,
    error: "Invalid input",
    displayTooltip: false,
    tooltip: ""
};

export const switchVisibilityFieldParams = {
    name: "isActive",
    type: "checkbox",
    placeholder: "",
    validationValue: true,
    displayLabel: true,
    label: "Enable product",
    displayError: true,
    error: "Select whether the product is active!",
    displayTooltip: false,
    tooltip: ""
};