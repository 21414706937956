// external libraries
import React, { useEffect } from "react";
import { Link } from "react-router-dom";


// Terms of Use page - this is the page where the user can view the terms of use of the site; taken directly from kokabora.com
const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<main className="d-flex flex-column">
        <p>
            <br /><br />
            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the https://www.kokabora.com website (the "Site") operated by Koka-Bora Creations ("Us", "We", or "Our"). Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, Users and others who access or use the Site. By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the Terms then you may not access the Site.
            <br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Nature of Product</span>
        </h3>
        <p>
            All products offered contain wood components from trees and burls found all over the world. Wood is a natural product and hence no two products will be the same even if they are created from the same species of wood or the same branch. Wood continues to 'live' even after it has been cut. Wood reacts to ambient atmospheric conditions to exhibit what is known as <Link className="text-primary" to={"http://www.finewoodworking.com/item/108898/calculating-for-wood-movement"} target="_blank">'seasonal wood movement'</Link>. As a result, the product may change its shape or develop minor surface checks over time. In addition, wood also reacts to sunlight and can potentially change color with time. Your purchase signifies that you're aware of these properties of the wood and will not hold Koka-Bora Creations, nor its directors, employees, partners, agents, suppliers, or affiliates liable for any damages to the product resulting from normal wear and tear or exposing the product to extreme conditions.<br />
            <br />
            All wood is purchased from stores that specialize in import and export of wood products from all over the world. As a result, all product descriptions will mention the wood species and any finishing material used in making of the product. Your purchase signifies that you have read the product description and are aware of the materials used. Koka-Bora Creations, its directors, employees, partners, agents, suppliers, or affiliates shall not be held liable to any personal or property damage resulting from contact with the wood. This includes but not limited to allergic reactions to the wood, personal injury from splinters, or strike against the body or property.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Purchases and Pre-Orders</span>
        </h3>
        <p>
            If you wish to purchase any product or service made available through the Site ("Purchase"), you may be asked to supply certain information relevant to your purchase including, and without limitation, your name, your billing address, your shipping information, and/or your credit card number and its expiration date. You represent and warrant that:<br />
            - You have the legal right to use any credit card(s) or other payment method(s) in connection with any purchase;<br />
            - The information you supply to Us is true, correct and complete.<br />
            <br />
            By submitting such information, you grant Us the right to provide the information to third parties (e.g. shipping company, credit card processor company) for purposes of facilitating the completion of purchases.<br />
            <br />
            All products are handmade and hence the stock level on the Site reflects that. You can still order products that are out of stock - in other words Pre-Order the product. We will manufacture the product - as closely as possible to the product image - to the desired quantity before the entire order is shipped.<br />
            <br />
            All product images are meant to provide a visual representation of all the various options that are available. The product description provides any additional details as to what is included with each option and what will/can be shipped. Your purchase signifies that in addition to looking at the product images, you have read the descirption and are aware of all details about the product.<br />
            <br />
            We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or Site availability; errors in the description or price of the product or Site; error in your order; fraudulent, unauthorized, or illegal activity; or other reasons.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Customizations</span>
        </h3>
        <p>
            We offer customization to some of Our items. These customization are directed by you and do not represent Our beliefs, customs, political views, etc. In case of disputes arising from the customization, you shall indemnify Koka-Bora Creations as stated in the "Indemnification" section of the Terms.<br />
            <br />
            Koka-Bora Creations reserves the right to refuse or otherwise cancel a customization if the customization is deemed complex or agreement between alternatives cannot be reached. In such cases, the customization costs will be refunded and item shipped to the customer.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Payments</span>
        </h3>
        <p>
            All online payments are securely processed by PayPal. There are two ways to pay:<br />
            - using your credit card: your payment is processed securely without leaving the Site<br />
            - using your PayPal account: you will be redirected to PayPal where you can log in with your PayPal credentials and complete the transaction.<br />
            <br />
            We offer in store payment for anyone who has the ability to visit Our physical store location at the St. Jacob's Farmer's market. All in store payments can be made by Cash, Credit Card, or PayPal. All in store credit card payments are processed by <Link className="text-primary" to={"https://squareup.com/ca/"} target="_blank">Square Inc.</Link> using their app. PayPal money transfers are processed by <Link className="text-primary" to={"https://www.paypal.com/"} target="_blank">PayPal</Link> using their app. Please note that if you choose to pay in store, your items will be held for 7 or otherwise negotiated number of calendar days following the order date. If the items are not paid by the set date, your order will be cancelled and the items returned to Our inventory.<br />
            <br />
            Payment for items - either in store or through the Site - constitutes your agreement to receive the goods and services as described on Our Site.<br />
            <br />
            All transactions are processed in Canadian dollars. For international customers, your credit card company will convert the amount using their current exchange rate and the converted amount will show on your statement in your currency.<br />
            <br />
            All customizations must be paid in full prior to their fulfillment.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Order Cancellations</span>
        </h3>
        <p>
            Non-Customized items: Cancellations are only accepted prior to product shipment. Please <Link className="text-primary" to={"#"}>Contact Us</Link> directly if you wish to cancel your order. Once your order has shipped, Cancellations are not accepted. Please refer to the Returns and Exchange section if you wish to return the products.<br />
            <br />
            Customized items: The following general rules would be considered.<br />
            - Items where no work has been done: cancellation is accepted without any fees.<br />
            - Work in progress items: cancellation fee would apply on case by case basis depending on how much effort has been put into making the item.<br />
            - Finished products: cancellations are not accepted.<br />
            <br />
            As stated in the Payment section, all orders that are marked as Payment in Store, but have not been paid by the set date are subject to cancellation.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Shipping</span>
        </h3>
        <p>
            All orders will be shipped as gifts within 2 business days from the date an order has been placed. Please note orders are processed sequentially - We fill them in the order in which they are received. If you have placed multiple orders, please inform Us and We may be able to accommodate combined shipping. Once We combine your shipping any excess postage will be refunded.<br />
            <br />
            We may be able to accommodate changes to your order provided that your order has not been shipped. If you wish to make changes to your order, please let Us know immediately. Note that changes to the order may delay the shipping date and may result in additional charges or refund. Any additional charges must be paid prior to shipping. Once the order has been shipped, We cannot make any changes.<br />
            <br />
            All shipping rates are based on parcel Weight and destination. Shipping rates are calculated automatically by the Site upon checkout according to the Weight of the items in your order and where you're having them shipped.<br />
            <br />
            We offer in store pick up for anyone who has the ability to visit Our physical location at the St. Jacob's Farmer's market. Please note the following conditions:<br />
            - If your order has not been paid, We will hold your items for 7 or otherwise negotiated number of calendar days from the date of your order. If they are not picked up and paid within the timeframe, your order will be cancelled and items returned to Our inventory.<br />
            - If your order has been paid, the items will be held in store indefinitely until you pick them up.<br />
            - If at any point, you decide to have the items shipped, you must pay your order in full including any shipping charges.<br />
            <br />
            Shipping care:<br />
            All items are inspected prior to shipment. Once your item is shipped, you will receive a confirmation notice. Please have a valid e-mail address to ensure you receive this notice. We do not assume responsibility for:<br />
            - Loss or damage due to shipping. The shipping company will be contacted and claim started,<br />
            - Delays in shipping due to incorrect address or other unforeseen circumstances,<br />
            - Missed communication due to invalid e-mail address<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Returns &amp; Exchanges</span>
        </h3>
        <p>
            Non-Customized items:<br />
            All items are inspected prior to shipping to ensure you receive a quality product. If you are not satisfied with the product, you may return it within 30 days of taking delivery of the package. Items returned must be undamaged and in original condition. Refunds will be issued once the item has been received and inspected. You must pay any shipping charges associated with the return shipment.<br />
            <br />
            For any items returned, the original purchase price will be refunded. Shipping costs, customs duties and fees will not be refunded.<br />
            <br />
            Customized items:<br />
            Items that are customized to your specific requirements are not eligible for return or refund.<br />
            <br />
            Damaged items:<br />
            Items damaged during shipping and handling by a courier or postal service is not eligible for return or refund. In such case, the shipping company will be contacted and a claim started.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Taxes</span>
        </h3>
        <p>
            Taxes on physical goods are based on the shipping address and follow the tax schedule below.
        </p>
        <table className="terms">
            <tbody>
                <tr>
                    <td align="center" width="255px"><strong>Items shipped to...</strong></td>
                    <td align="center" width="70px"><strong>GST</strong></td>
                    <td align="center" width="70px"><strong>PST</strong></td>
                    <td align="center" width="70px"><strong>HST</strong></td>
                </tr>
                <tr>
                    <td>British Columbia</td>
                    <td>5%</td>
                    <td>7%</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Alberta</td>
                    <td>5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Saskatchewan</td>
                    <td>5%</td>
                    <td>6%</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Manitoba</td>
                    <td>5%</td>
                    <td>8%</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Ontario</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>13%</td>
                </tr>
                <tr>
                    <td>Quebec</td>
                    <td>5%</td>
                    <td>9.975%*</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Prince Edward Island</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>New Brunswick</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Nova Scotia</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>New Foundland and Labrador</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Yukon Territories</td>
                    <td>5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Northwest Territories</td>
                    <td>5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Nunavut</td>
                    <td>5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Outside Canada**</td>
                    <td>0%</td>
                    <td>0%</td>
                    <td>0%</td>
                </tr>
            </tbody>
        </table>

        <p>*this is the QST rate applied to the selling price of the item<br />
            **International orders are not subject to Canadian sales taxes, but the orders may be subject to Customs, Duties, Brokerage and other fees payable by you at the time of receipt. These charges are in addition to your order total<br />
            <br />
            Taxes on downloadable items are based on the 13% Ontario HST regardless of where the order is domestic or international.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Availability, errors, and inaccuracies</span>
        </h3>
        <p>
            We are constantly updating Our offerings of products and services on the Site. The products or services available on Our Site may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information on the Site and in Our advertising on other web sites.<br />
            <br />
            We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Accounts</span>
        </h3>
        <p>
            When you create an account with Us, you must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on Our Site.<br />
            <br />
            You are responsible for safeguarding the password that you use to access the Site and for any activities or actions under your password, whether your password is with Our Site or a third-party Site.<br />
            <br />
            You agree not to disclose your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br />
            <br />
            You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Copyright Policy</span>
        </h3>
        <p>
            We respect the intellectual property rights of others. It is Our policy to respond to any claim that content posted on the Site infringes the copyright or other intellectual property infringement ("Infringement") of any person.<br />
            <br />
            If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Site, you must <Link className="text-primary" to={"#"}>Contact Us</Link> and include in your notice a detailed description of the alleged Infringement.<br />
            <br />
            You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any content is infringing your copyright.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Intellectual Property</span>
        </h3>
        <p>
            The Site and its original content are and will remain the exclusive property of Koka-Bora Creations. The Site is protected by copyright, trademark, and other laws of both the Canada and foreign countries. Our trademarks and trade dress may not be used in connection with any product or Site without the prior written consent of Koka-Bora Creations.<br />
            <br />
            The Site features and functionality are provided by OpenCart and its various extensions; the theme is provided by Bossthemes. These are the property of the providers and are used either by Generic Public License or a purchased license. Some of these extensions may have been modified in house to provide a best fit for the Site.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Links To Other web Sites</span>
        </h3>
        <p>
            Our Site may contain links to third-party web Sites or services that are not owned or controlled by Koka-Bora Creations.<br />
            <br />
            Koka-Bora Creations has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web Sites or services. You further acknowledge and agree that Koka-Bora Creations shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web Sites or services.<br />
            <br />
            We strongly advise you to read the Terms and conditions and privacy policies of any third-party web Sites or services that you visit.<br />
            <br />
        </p>
        <h3><span className="info text-primary">Termination</span></h3>
        <p>
            We may terminate or suspend access to Our Site immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br />
            <br />
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.<br />
            <br />
            We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br />
            <br />
            Upon termination, your right to use the Site will immediately cease. If you wish to terminate your account, you may simply discontinue using the Site.<br />
            <br />
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Indemnification</span>
        </h3>
        <p>
            You agree to defend, indemnify and hold harmless Koka-Bora Creations and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Site, by you or any person using your account and password, or b) a breach of these Terms.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Limitation Of Liability</span>
        </h3>
        <p>
            In no event shall Koka-Bora Creations, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, Use, goodwill, or other intangible losses, resulting from<br />
            - Your access to or use of or inability to access or use the Site;<br />
            - Any conduct or content of any third party on the Site;<br />
            - Any content obtained from the Site; and<br />
            - Unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not We have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose .<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Disclaimer</span>
        </h3>
        <p>
            Your use of the Site is at your sole risk. The Site is provided on an "AS IS" and "AS AVAILABLE" basis. The Site is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.<br />
            <br />
            Koka-Bora Creations does not warrant that<br />
            - The Site will function uninterrupted, secure or available at any particular time or location;<br />
            - Any errors or defects will be corrected;<br />
            - The Site is free of viruses or other harmful components; or<br />
            - The results of using the Site will meet your requirements.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Exclusions</span>
        </h3>
        <p>
            Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Governing Law</span>
        </h3>
        <p>
            These Terms shall be governed and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law provisions.<br />
            <br />
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between Us regarding Our Site, and supersede and replace any prior agreements We might have between Us regarding the Site.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Changes</span>
        </h3>
        <p>
            We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will try to provide at least 30 days notice prior to any new Terms taking effect. What constitutes a material change will be determined at Our sole discretion.<br />
            <br />
            By continuing to access or use Our Site after those revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, please stop using the Site.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Contact Us</span>
        </h3>
        <p>
            If you have any questions about these Terms, please <Link className="text-primary" to={"#"}>Contact Us</Link>.<br />
            <br />
        </p>
        Last updated: February 8, 2019<p></p>
    </main>);
};

export default Terms;

