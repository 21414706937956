// external libraries
import React from "react";


// component rending a custom message at the top of the customer product listing page. This could serve as marketing message, or special promo code etc
const ProductPageMessage = () => {
    // potential for database drivern single message that the user can update at any time
    const message = {
        title: "Indulge in the natural beauty of wood...",
        body: "Experience the finest craftsmanship with our range of writing instruments, meticulously handmade from premium domestic and exotic hardwoods. Our collection features ballpoint, fountain, and rollerball pens, along with a select range of mechanical pencils. Each instrument uses standard cartridges available worldwide, ensuring convenience and reliability. Elevate your writing experience with our elegant and unique pieces.",
        url: "/media/non-products/messages/slide-4.jpg"
    };

    // rendering the message on the screen. when the width goes below bootstap's lg breakpoint, the image is hidden
    return (
        <div className="card d-flex m-2 p-2 border border-2 shadow-sm" >
            <div className="card-body d-flex justify-content-start align-items-center gap-2">
                {message.url && <div className="d-none d-lg-flex col-4">
                    <img src={message.url} className="col-12" alt="product slide "/>
                </div>}
                <div className="card-text d-flex flex-column fs-5">
                    <h3>{message.title}</h3>
                    <p>{message.body}</p>
                </div>
            </div>
        </div>
    );
};

export default ProductPageMessage;

