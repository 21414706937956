// external libraries
import React, { useState } from "react";
import { faHeart, faHeartCirclePlus, faRefresh, faShoppingCart, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// components
import ActionButton from "../../../ActionButton";
import PriceInformation from "../../Common/PriceInfo";
import QuantityInput from "../../Common/QuantityInput";
import ProductImages from "../../Common/Images";


// redux slice
import { addItemToCart } from "../../../../../features/cart/cartSlice";
import { LOADING } from "../../../../../features/status";
import { updateWishlist } from "../../../../../features/wishlist/wishlistSlice";
import { selectWishlistProductById } from "../../../../../features/wishlist/wishlistSelectors";
import { selectCartProductById } from "../../../../../features/cart/cartSelectors";


// this component displays the product card on the dashboard page - this is slightly different from the product card displayed on the products page
const DashboardProductCard = ({ product, display }) => {
    const loading = useSelector(state => state.cart.status === LOADING);

    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(product?.quantity || 1);
    const productInCart = useSelector(state => selectCartProductById(state, product.id));
    const productInWishlist = useSelector(state => selectWishlistProductById(state, product.id));
    const icon = productInWishlist ? faHeartCirclePlus : faHeart;


    // function to update the cart with the new quantity of the product
    // - deleteItem: boolean to determine if the item should be deleted from the cart; deletion happens when the quantity is set to 0
    const handleCartUpdate = (deleteItem = false) => {
        dispatch(addItemToCart([
            {
                "id": product.id,
                "quantity": deleteItem ? 0 : quantity
            }
        ]));

    };

    // function to update the wishlist with the product
    // this is for the use case when user want to move product from cart to wishlist
    const handleWishlistUpdate = () => {
        dispatch(updateWishlist([
            {
                "id": product.id
            }
        ]));

    };


    // returns the product card component
    // the component is used to display 
    // - the products in cart
    // - the products in wishlist
    // - the products in the order details once fetched from stripe api
    return (
        <div className="d-flex flex-column flex-md-row col-12 mb-2">
            {/* product card shows the main image of the product */}
            <div className="d-flex col-12 col-md-5 col-xl-3 p-2 "> 
                <Link to={`/products/${product?.id}`}>
                    <ProductImages productDetails={product} showAdditional={false} mainOnly={true} />
                </Link>
            </div>
            {/* product card display the title and categories of the product */}
            <div className="d-flex flex-column flex-xl-row col-12 col-md-7 col-xl-9 p-2">
                <div className="d-flex col-12 col-xl-9 px-2 flex-column mb-5 mb-xl-0"> {/* this is the product title */}
                    <div className="d-flex col-12 text-primary text-uppercase">
                        <h3>
                            <Link to={`/products/${product?.id}`} className="text-decoration-none text-primary">
                                {product?.name}
                            </Link>
                        </h3>
                    </div>
                    <div className="mb-2">{
                        product?.categories.map((category, index, categories) => {
                            if (index === categories.length - 1) {
                                return `${category?.name}`;
                            } else {
                                return `${category?.name}, `;
                            }
                        })
                    }</div>
                    {/* 150 characters of the description
                    // user can click on the image or title to view the full product page */}
                    <div dangerouslySetInnerHTML={{__html:`${product?.description.substring(0, 149)}...` }} />
                </div>
                {/* product card shows the price, quantity input, and action buttons */}
                <div className="d-flex col-6 col-xl-3 px-2 flex-column">
                    {/* this is the price information of the user group; customer view displays only the price for the customer group not all */}
                    <PriceInformation price={product?.pricing?.[0] || []} />

                    {/* displaysthe user selected quantity
                    // does not apply to the wishlish  
                    // applies to cart and order details*/}
                    {display?.quantity?.component &&
                        <QuantityInput quantity={quantity} updateQuantity={setQuantity} disabled={display?.quantity?.disabled} showButtons={display?.quantity?.buttons} timesSign={ display?.quantity?.times} />
                    }

                    {/* action button to move the rpodcut from wishlist to cart
                    // applies only on wishlist */}
                    {(display?.cart?.add && !productInCart) &&
                        <ActionButton icon={faShoppingCart} text={"Add to Cart"} loading={loading} onClick={() => {
                            handleCartUpdate();
                            handleWishlistUpdate();
                        }} />

                    }

                    {/* action button to update the quantity selected
                    // disabled when quantity is unchanged */}
                    {display?.cart?.update &&
                        <ActionButton icon={faRefresh} text={"Update Cart"} loading={loading} onClick={() => {
                            handleCartUpdate();
                        }} disabled={product.quantity === quantity} />
                    }

                    {/* action button to move the rpodcut from wishlist to cart
                    // applies only on cart */}
                    {(display?.wishlist && !productInWishlist) &&
                        <ActionButton icon={icon} text={"Move to Wishlist"} loading={loading} onClick={() => {
                            handleWishlistUpdate();
                            handleCartUpdate(true);
                        }} />
                    }

                    {/* action button to remove product from cart or wishlist */}
                    {(display?.remove?.cart || display?.remove?.wishlist) &&
                        <ActionButton icon={faTrash} text={"Remove"} loading={loading} onClick={() => {
                            display?.remove?.cart && handleCartUpdate(true);
                            display?.remove?.wishlist && handleWishlistUpdate();
                        }} />
                    }
                </div>
            </div>
        </div>
    );
};

export default DashboardProductCard;