// external libraries
import React, { Fragment } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

// components
import ActionButton from "../../../ActionButton";

// quantity input component that is displayed on the product card. this is where the user selects the quantity of the product they want to purchase
// - quantity: the quantity of the product
// - updateQuantity: the function to be called when the quantity is updated
// - disabled: boolean to determine if the quantity input should be disabled (i.e. the product is updated to the desired quantity), button is enabled when user selects new quantity
// - showButtons: boolean to determine if the plus and minut buttons on the side of the input should be displayed
const QuantityInput = ({ quantity, updateQuantity, disabled=false, showButtons=true }) => {
    // function to increase the quantity
    const handleIncrease = () => {
        updateQuantity(++quantity);
    };

    // function to decrease the quantity - error checking for minimum quantity of 1
    const handleDecrease = () => {
        if (quantity > 1) {
            updateQuantity(--quantity);
        }
    };

    // the quantity input component
    return (
        <Fragment>
            <div className="d-flex col-12 justify-content-center">
                {/* minus button to the left of the input */}
                {showButtons && <div className="d-flex flex-folumn col-2">
                    <ActionButton icon={faMinus} text={""} onClick={() => { handleDecrease(); }} disabled={disabled} />
                </div>
                }
                {/* the input
                TODO: consider using the common component for input */}
                <div className={`d-flex flex-folumn ${showButtons ? "col-8" : "col-12"} justify-content-center`}>
                    <input type="text" id="quantity" name="quantity" placeholder="123" className="form-control text-center mb-2 " readOnly={true} value={`x ${quantity}`} disabled={disabled} />
                </div>
                {/* plus sign to the right */}
                {showButtons && <div className="d-flex flex-folumn col-2">
                    <ActionButton icon={faPlus} text={null} onClick={() => handleIncrease()} disabled={disabled} />
                </div>
                }
                
            </div>
        </Fragment>
    );

};

export default QuantityInput;