// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// providers
import { client } from '../../app/apollo';

// redux slice
import { QUERY_USER_DATA } from './userQueries';
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { setLoginError, setError, setFulfilled, setLoading } from '../ui/uiSlice';

export const userAdapter = createEntityAdapter();


const initialState = {
    status: IDLE,
    error: null,
    isStaff: false,
    isCustomer: false,
    isLoggedIn: false,
    id: null
};


// the UI slice which has the "global" status is going to be updated with the dispatch event
export const fetchUserData = createAsyncThunk('user/fetchUserData',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(setLoading());
        try {
            const response = await client.query({
                query: QUERY_USER_DATA,
                variables: {},
                fetchPolicy: 'network-only'
            });
            dispatch(setFulfilled());
            return response;
        } catch (error) {
            const login_error = error.graphQLErrors.some(error => error?.extensions?.login_error);
            if (login_error) { dispatch(setLoginError()); } else {dispatch(setError());}
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });



const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userReset(state, action) {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            // fetching user data
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and the user data is returned; adding the information is done in the separate slices
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(fetchUserData.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;

            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;

                state.isStaff = action?.payload?.data?.userData?.isStaff || false;
                state.isCustomer = action?.payload?.data?.userData?.isCustomer || false;
                state.isLoggedIn = action?.payload?.data?.userData?.isLoggedIn || false; 
                state.id = action?.payload?.data?.userData?.id || null;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {
                    ...initialState,
                    status: login_error ? LOGIN_ERROR : FAILED,
                    error: login_error ? LOGIN_ERROR : FAILED
                };
            });
    },
});

export const { userAdded, userUpdated, reactionAdded, userReset } = userSlice.actions;

export default userSlice.reducer;

