// external libraries
import { gql } from '@apollo/client';

// graphql query to get product information
export const QUERY_PRODUCTS = gql`
query Products {
  products {
    id
    sku
    name
    description
    isActive
    images {
      url
      isMain
    }
    categories {
      id
      isActive
      name
      parent {
        id
        isActive
        name
      }
    }
    weight {
      scale {
        name
        code
      }
      value
    }
    length {
      scale {
        name
        code
      }
      value
    }    
    width {
      scale {
        name
        code
      }
      value
    }  
    height {
      scale {
        name
        code
      }
      value
    }
    modifiedAt
    pricing {
      userGroup {
        id
        name
      }
      regular {
        currency {
          code
          name
          symbol {
            left
            right
          }
        }
        value
      }
      special {
        currency {
          code
          name
          symbol {
            left
            right
          }
        }
        value
        dates {
          fromDate
          toDate
        }        

      }
    }
  }
}
`;