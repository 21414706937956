// external librareis
import React from "react";
import { faHand, faTree, faWarehouse } from "@fortawesome/free-solid-svg-icons";

// components
import Message from "../Message";

// messages list: potential for creating database driver messages where the user enters any number of messages on their own
const MessageList = () => {
    const messages = [
        {
            icon: faWarehouse,
            text: "Experience the beauty of nature with a conscience. Our writing instruments are crafted from wood sourced from trusted suppliers, ensuring ethical practices every step of the way. Embrace sustainability and style in your writing journey."
        },
        {
            icon: faHand,
            text: "Elevate your writing journey with our handmade writing instruments, where every curve and contour is carefully sculpted by skilled hands. Embrace the authenticity of craftsmanship and let your thoughts flow effortlessly onto paper.",
        },
        {
            icon: faTree,
            text: "Indulge in the natural beauty of wood with our exquisite selection of writing instruments. Each piece tells a story of craftsmanship, showcasing the rich textures and vibrant colors of domestic and exotic hardwoods."

        }        
    ];


    // rendering the message and the icon on the landing page
    return (
        <div className="d-flex flex-column position-relative my-5">
            <div className="d-flex flex-column align-items-center fs-1 mb-2 text-secondary">
                <span className="fst-italic">Our Values</span>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-evenly">
                {messages.map((message, index) => {
                    return (<div className="d-flex col-12 col-md-4" key={index}>
                        <Message icon={message.icon} message={ message.text } />
                    </div>);

                })}
            </div>
        </div>
    );
};

export default MessageList;

