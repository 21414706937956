// external libraries
import React from "react";

// custom css
import "./overlay.css";

// overlay with the base functionality 
// overlayAction is whether menu is open or closed from the redux state
// TODO: potential to investigate using the redux state to query whether a menu is open or not
// overlay: the overlay created by the menu component
// handleMenuClick: the function responsible for opening of closing the menu
// closeOnClick: attribute stating whether the menu should be closed after use clicks on a menu option
const BaseOverlay = ({ overlayAction, overlay, handleMenuClick, closeOnClick = true }) => {
    return (
        <div className={`d-flex align-items-baseline justify-content-center overlay bg-light ${overlayAction ? "overlay-open" : "overlay-close"}`}
            onClick={closeOnClick ? handleMenuClick : undefined}
        >
            <div className="d-flex col-12 col-xxl-10 justify-content-end ">
                {overlay}
            </div>
        </div>
    );
};

export default BaseOverlay; 