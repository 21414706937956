// external libraries
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

// components
import Loading from "../../../common/Loading";

// redux slice
import { LOADING } from "../../../../features/status";
import { selectOrderIds } from "../../../../features/orders/ordersSelectors";
import { orderSelect } from "../../../../features/orders/ordersSlice";

// custom css
import "./index.css";

// this is the dropdown component that lets the user select from the orders they have placed
const OrderSelector = () => {
    const loading = useSelector((state) => state.products.status === LOADING || state.user.status === LOADING);
    const orders = useSelector((state) => selectOrderIds(state));
    const dispatch = useDispatch();
    
    // gets the value of the global's state selected order; if there is none, it sets the value to "Select an order"
    let dropdownText = useSelector((state) => state.orders.selectedOrder);
    if (dropdownText ==="") {
        dropdownText = "Select an order";
    }

    // updates the selected order in the global state with the user selected order from the dropdown
    const handleOrderSelect = (event) => {
        dispatch(orderSelect(event.target.dataset.id));
    };

    // resets the selected order in the global state when the component is mounted
    useEffect(() => {
        dispatch(orderSelect(""));
    }, []);

    // if waiting for any information to load, display the loading component
    if (loading) {
        return (<Loading />);
    }

    // returns the order selector component - the component encompasses the entire div, the title, the dropdown
    return (
        <div className="border border-1 rounded shadow p-2 mb-2">
            <div className="d-flex col-12 align-items-center">
                <h3 className="d-flex gap-2 mb-0">
                    <FontAwesomeIcon icon={faLayerGroup} />
                    <span>Order List</span>
                </h3>
            </div> {/* this is the title */}
            <hr className="mb-4" />
            <div className="d-flex col-12 justify-content-center mb-4">
                {orders.length === 0 ? <span className="text-primary fs-3">No orders to display</span> :
                    <div className="d-flex min-width dropdown">
                        <button className="col-12 btn btn-primary dropdown-toggle p-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="p-2 fs-5" id="orderSelectDropdown">{dropdownText}</span>
                        </button>
                        <ul className="dropdown-menu col-12 vh-30 overflow-auto">
                            {orders.map((order) => {
                                return (
                                    <li key={order}>
                                        <button className="col-12 dropdown-item fs-5" onClick={(event) => handleOrderSelect(event)} data-id={order}>{order}</button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                }
            </div> 
        </div>
    );
};

export default OrderSelector;