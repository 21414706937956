// external libraries
import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; 
import { Navigate } from "react-router-dom";

// redux slice
import { wishlistReset } from "../../../features/wishlist/wishlistSlice";
import { cartReset } from "../../../features/cart/cartSlice";
import { ordersReset } from "../../../features/orders/ordersSlice";
import { stripeReset } from "../../../features/stripe/stripeSlice";
import { userReset } from "../../../features/user/userSlice";
import { fetchProducts } from "../../../features/products/productsSlice";


// utilities
import auth from "../../../utils/auth";

// logout page that clears the redux store and logs the user out
const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {  
        dispatch(wishlistReset());
        dispatch(cartReset());    
        dispatch(ordersReset());
        dispatch(stripeReset());
        dispatch(userReset());
        // must refetch products to get the correct prices
        dispatch(fetchProducts());
    }, []);

    auth.logout();
    return (<Navigate to="/" replace />);
};

export default Logout;