
import { createSelector } from "@reduxjs/toolkit";
import { stripeAdapter } from "./stripeSlice";
import { selectProductById } from "../products/productsSelectors";


export const {
    selectAll: selectAllStripeSessions,
    selectById: selectStripeSessionById,
    selectIds: selectStripeSessionIds,
} = stripeAdapter.getSelectors((state) => state.stripe);


// returns the details of the stripesession based on the ID
// returns the session and the products in the session; some of the product information is taken from the selectPRoductById selector while the price is overriden based on the session
export const selectStripeSessionInfoById = createSelector(
    [(state, sessionId) => selectStripeSessionById(state, sessionId), (state) => state],
    (session, state) => {
        // product is a product type so it appears as a subsequent object as in products[0].product.id
        // the code below will flatten it so that it appears as products[0].id
        const sessionProducts = {
            session: session?.session,
            products: session?.data.map((product) => {
                const productInfo = selectProductById(state, product.price.product);
                return { ...productInfo, 
                    quantity: product.quantity, 
                    pricing: productInfo?.pricing.map((price, index) => {
                        return {
                            ...price,
                            regular: {value: product.price.unit_amount / 100, 
                                      currency: {
                                        code: product.price.currency
                                    }},
                            special: null
                        };
                    })
                };
            })
        };
        return sessionProducts;
    }
);
