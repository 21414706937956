// external libraries
import 'symbol-observable';
import React from 'react';
import { createRoot } from 'react-dom/client';

// application
import App from './App';

// custom css
import './index.css';

// Create a root concurrent rendering tree
const root = createRoot(document.getElementById('root'));

// Render your app within the root concurrent rendering tree
root.render(<App />);