// external libraries
import React from "react";

// components
import Logo from "../Logo";

// component that is mean to be the common features between login and registration forms. 
const FormContainer = ({ form }) => {

    return (<main className="d-flex flex-column">
        <section className="d-flex justify-content-center align-items-center m-1 m-md-5 mb-5" >
            <div className="d-flex card shadow p-0 p-lg-5 col-12 justify-content-center align-items-center" >
                <div className="d-flex col-12 justify-content-center align-items-center">
                    <div className="col-lg-5 d-none d-md-flex">
                        <img src="/media/non-products/messages/login-reg.jpg" alt="login form" className="d-flex img-fluid shadow" />
                    </div>
                    <div className="col-12 col-lg-7 d-flex align-items-center">
                        <div className="d-flex flex-column card-body p-2 p-md-4 text-black">
                            <div className="d-none d-xl-flex mb-3">
                                <Logo />
                            </div>
                            {form}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>);
};

export default FormContainer;

