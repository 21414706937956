// external libraries
import { jwtDecode } from 'jwt-decode';

// Auth service class to handle user authentication, JWT token checks, and user logout
class AuthService {
    // checks if the user is logged in
    loggedIn(userId) {
        // user is logged in when:
        // 1. there is a token in sessionStorage
        // 2. the token is not expired
        // 3. the user id in the token matches the userId in the argument
        const token = this.getToken();
        let tokenUserId = null;
        try {
            tokenUserId = jwtDecode(token)?.user_id;
        } catch (err) {
            return false;
        }
        return !this.isTokenExpired(token) && !!tokenUserId && tokenUserId === userId;
    }

    // checks if the token is expired
    isTokenExpired(token) {
        try {
            const decoded = jwtDecode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else return false;
        } catch (err) {
            return true;
        }
    }

    // Retrieves the user token from sessionStorage
    getToken() {
        return sessionStorage.getItem('id_token');
    }

    // Saves user token to sessionStorage
    login(idToken) {
        sessionStorage.setItem('id_token', idToken);
    }

    // Clear user token and profile data from sessionStorage
    logout() {
        sessionStorage.removeItem('id_token');
    }
}

const auth = new AuthService();

// export the auth service instance
export default auth; 