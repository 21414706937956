// external libraries
import React from "react";

// components
import Logo from "../Logo";
import Menu from "../Menu";

// custom css
import "./index.css";

// navigation component holding everything that is at the top of the page
// this includes the logo (2 images, where one is hidden on smaller devices)
// navigation buttons (number of buttons depend on type of user)
const Nav = () => {   
    return (
    <nav className="d-flex col-12 flex-row">
        <div className="d-flex col-6 col-sm-9">
            <Logo />
        </div>
        <div className="d-flex col-6 col-sm-3">
            <Menu /> 
        </div>
    </nav>
    );
};

export default Nav;

