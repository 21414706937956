// external libraries
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Creating common component for buttons so that all buttons use the same component for the buttons (WIP)
const AdminActionButton = ({ icon, text, onClick, primary = false, disabled = false, loadEvent = false }) => {
    const [buttonClicked, setButtonClicked] = useState(false);

    // common funtionality of the button is to change the icon to a spinner and spin it
    const clickButton = (event) => {
        event.preventDefault();
        setButtonClicked(true);
    };

    // turning off the spinning when information has stopped loading
    if (!loadEvent && buttonClicked) {
        setButtonClicked(false);
    }
    
    return (
        <div className="d-flex flex-row mb-2 bg-white rounded"> {/* this is the product action buttons */}
            <button className={`p-2 btn ${primary ? "btn-primary" : "btn-outline-primary"}  gap-2 col-12 d-flex justify-content-center align-items-center`} disabled={loadEvent || buttonClicked || disabled} onClick={(event)=>{
            clickButton(event);
            onClick(event);
        }}  >
            <FontAwesomeIcon icon={buttonClicked ? faSpinner : icon}  className={`${buttonClicked ? "fa-spin" : ""} d-flex`} />
                {text && <span className="d-none d-lg-flex">{text}</span>}
        </button>
    </div>
    );

};

export default AdminActionButton;