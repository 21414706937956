// external libraries
import React, {useEffect} from "react";

// components
import Banner from "../../../components/common/Banner";
import ProductList from "../../../components/customer/Products/Display/List";
import MessageList from "../../../components/common/Messages/LandingList";


// the landing page of the application. It filters max 6 products to show as featured products
const Landing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (<main className="d-flex flex-column">
        <Banner />
        <ProductList title="Featured Products" maxCount={6} wrap={false} />
        <MessageList />
    </main>);
};

export default Landing;

