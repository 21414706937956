// external libraries
import { createSelector } from '@reduxjs/toolkit';

// redux slice
import { selectProductById } from "../products/productsSelectors";

// defining selectors based on the state
// selects the cart id from the state
export const selectCartId = (state) => state.cart.id;
// selects the cart containing the user products from the state
const selectCart = (state) => state.cart;

// selects the cart from the state; this is similar to the selectCartId, but circumvents a redux memoizing warning
export const selectUserCartId = createSelector(
    [selectCartId],
    (cartId) => cartId
);

// returns the cart products from the state in the format {id: product.id, quantity: product.quantity} and circumvents a redux memoizing warning
export const selectAllCartProducts = createSelector(
    [selectCart],
    (cart) => {
        return cart.products.map((cartProduct) => {
            return {
                id:cartProduct.product.id, 
                quantity:cartProduct.quantity
            };
        });
    }
);

// returns all details for the products in the cart; since the products in the cart are store as {id: product.id, quantity: product.quantity}, we need to get the product details from the products slice
export const selectAllCartProductDetails = createSelector(
    [(state) => state.cart.products, (state) => state],
    (cartProducts, state) => {
        return cartProducts.map((cartProduct) => {
            return {
                ...cartProduct,
                ...selectProductById(state, cartProduct.product.id)
            };
        });
    }
);

// returns the cart product ids from the state as an array
export const selectCartProductIds = createSelector(
    [(state) =>state.cart.products],
    (cartProducts) => {
        return cartProducts.map(product=>product.product.id);
    }
);

// returns the single cart product details from the cart state
export const selectCartProductById = (state, productId) => state.cart.products.find(product=>product.product.id === productId);

// returns the cart product subtotal of all products in the cart. the subtotal is returned as an object with the currency and value
export const selectCartProductSubtotal = createSelector(
    [selectAllCartProductDetails, (state) => state],
    (cartProducts, state) => {
        // if no products in the cart, return and empty string for the value. This will force the cart to show N/A
        if (cartProducts.length === 0) {
            return { currency: { code: "cad"}, value: "" };
        }
        const cartSubTotal = cartProducts.reduce((subTotal, currentProduct) => {
            const productDetails = selectProductById(state, currentProduct.id);
            subTotal += (productDetails?.pricing?.[0]?.special ? (productDetails?.pricing?.[0]?.special?.value || 0) : (productDetails?.pricing?.[0]?.regular?.value || 0)) * currentProduct.quantity;
            return subTotal;
        }, 0);
        // when currencies are implemented, the line below must be updated to get 
        //  - the currency from the cookie (client preference)
        //  - the default currency from the global state
        return { currency: cartProducts?.[0]?.pricing?.[0]?.regular?.currency, value:cartSubTotal };
    });


