// external libraries
import React from "react";

// component to return disclaimer on the checkout page
const Disclaimer = () => {
    return (
        <div>
            <h1 className="text-primary">Disclaimer</h1>

            <p>Welcome to our test e-commerce website! Please be advised of the following important points:</p>
            <ol>
                <li><strong className="text-primary">No Personal Data Use</strong>: This website is for testing purposes only. Any data entered, including personal information, will not be stored, processed, or used in any way.
                </li>
                <li><strong className="text-primary" >No Actual Purchases</strong> No real products will be purchased or shipped from this site. Any transactions simulated on this platform are for testing purposes only and have no real-world impact.
                </li>

                <li><strong className="text-primary">Use Stripe's Test Credit Card</strong> When testing payment functionality, please use Stripe's test credit card numbers. Do not use real credit card information on this site. Any payment information entered is purely for testing and simulation purposes and will not be processed.
                </li>

                <li><strong className="text-primary">Do Not Use Real Credit Cards</strong> Please do not use real credit card information on this site. Any payment information entered is purely for testing and simulation purposes and will not be processed.
                </li>

                <li><strong className="text-primary">No Real Orders</strong> Any orders placed on this website are not valid and will not be fulfilled. This includes any product selections, quantities, or other order details.
                </li>

                <li><strong className="text-primary">Test Environment</strong> This website is a test environment and is not connected to any real e-commerce backend systems. It is intended for testing functionality, user experience, and other non-production activities.
                </li>

                <li><strong className="text-primary">Data Security</strong> While we strive to maintain a secure environment, this site is not designed for handling real personal or payment data. Please refrain from entering any sensitive information.
                </li>

                <li><strong className="text-primary">No Customer Support</strong> As this is a test site, no customer support is provided. Any issues or inquiries related to this site will not be addressed.
                </li>

                <li><strong className="text-primary">User Responsibility</strong> By using this test website, you acknowledge and agree to these terms and understand that any data entered is for testing purposes only and will not result in real transactions or data storage.
                </li>
            </ol>
            Thank you for understanding and helping us test our e-commerce platform! If you have any questions or concerns, please do not hesitate to reach out to us via our designated testing feedback channels.
        </div>
    );
};

export default Disclaimer;