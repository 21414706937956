// external libraries
import React from "react";

// component displaying an error message. Used primarily for the input components and apeparing below the input
const Error = ({ message }) => {
    return (
        <div className="col-12 alert alert-danger" dangerouslySetInnerHTML={{__html: message}} />
    );
};

export default Error;