// external libraries
import { faGears, faScaleUnbalanced, faRuler, faCoins, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Cookie from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";

// redux slice
import { fetchProducts } from "../../../../features/products/productsSlice";
import { SUCCESS } from "../../../../features/status";


// creates a custom overlay of all settings that are currently part of the global state
// the way the settings are done is by setting a cookie and then passing the cookie to django. Django handles the conversio from one unit to another
// TODO: investigate having the conversion done on the front end to speed up data retreival
const SettingsOverlay = ({ settings }) => {
    const dispatch = useDispatch();
    const status = useSelector(state => state.products.status === SUCCESS);

    // sets the cookie to a new value and calls the fetch products thunk to get the information from django
    const handleSettingChange = (key, code) => {
        // slight delay is used in order to let the drowndown box close before dispatching events
        setTimeout(() => {
            Cookie.set(key, code);
            // setCurrentSetting(Cookie.get(key));
            dispatch(fetchProducts());
        }, 10);

    };

    // creates custom UL with the settings menu; creates a setting menu for all settings that are part of the UI redux menu. Currently only three settings are envisioned:
    // weight
    // physical dimensinos
    // currency (future release)
    return (
        <ul className="d-flex dropdown-menu border-0 bg-transparent px-2 flex-column fs-4 list-group ">
            {
                // for each setting go through the available values and create a dropdown
                Object.keys(settings).map(key => {
                    let icon = faGears;
                    switch (key) {
                        case "weight":
                            icon = faScaleUnbalanced;
                            break;
                        case "length":
                            icon = faRuler;
                            break;
                        case "currency":
                            icon = faCoins;
                            break;
                        default:
                            icon = faGears;
                    }


                    return (
                        <li className={`d-flex list-group-item gap-2 justify-content-end border-0 bg-transparent `    } key={key}>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle fs-4 d-flex align-items-center gap-2" type="button" data-bs-toggle="dropdown" aria-expanded="true"  disabled={!status}>
                                    <FontAwesomeIcon icon={status ? icon : faSpinner} className={status ? "" : "fa-spin"}/><span>{key}</span>:<span className="text-primary">{Cookie.get(key)}</span>
                                </button>
                                <ul className="dropdown-menu" >
                                    {

                                        // considering there will potentially be multiple settings, the better user experience is to allow the user to make changes to all settings before closing the menu
                                        // as such,we are stopping the propagation of the event, however, that will also stop the Bootstrap's to close the dropdown
                                        // therefore we have out own close event
                                        // potential defect: while page is loading, the person can click numerous times on the setting and change it. 
                                        settings[key].map(setting => {
                                            return (<li className="d-flex list-group-item gap-2 justify-content-end border-0 bg-transparent" key={setting.code} >
                                                <button className="btn fs-4 d-flex gap-2" type="button" data-bs-toggle="" aria-expanded="false"  onClick={() => handleSettingChange(key, setting.code)}>
                                                    <span className="d-flex">{setting.name}</span><span className="d-flex text-primary">{setting.code}</span>
                                                </button>
                                            </li>);

                                        })
                                    }
                                </ul>
                            </div>
                        </li>

                    );
                })
            }
        </ul>
    );
};

export default SettingsOverlay;