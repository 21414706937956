// exgternal libraries
import React, { useState, } from "react";

// components
import Error from "../Error";

// component that holds the dropdown checkbox functionality. These are basically two components in one 
// the main component is a dropdown
// dropdown list is a checkbox component
// at the moment this is used for the category selection where the user checks off from a list of categories
// the benefit is that it is a compact component 
// the checkbox component within the dropdownchecklist does not follow the checkbox component as the component is embedded inside the dropdown and the styling clashes
// the information for both the dropdown and the checkboxes must be defined in /utils/fieldParameters.js
const DropdownCheckboxField = ({  formUpdate, dropDown, dropDownValue, dropDownList, checkbox, formState}) => {
    const [isValid] = useState(true);

    const handleInputChange = async (event) => {
        // the component handles the event change and then sends the updated information to update the formState. 
        const fakeEvent = {
            currentTarget: {
                name: "categories",
                value: event.currentTarget.checked ? [...formState[0].categories, event.currentTarget.value] : formState[0].categories.filter(category => category !== event.currentTarget.value)
            }
        };
        formUpdate(fakeEvent);
        // disables the check that user must select at least one category. upon changes there's a brief moment where the error message gets triggered while the formState is updated. This prevents this flicker for now
        // TODO: investigate how to show the error message
        // setIsValid(formState[0].categories.length === 0);
    };

    // renders the dropdown with the checkboxes
    // TODO: invesetigate the hardcoding of "categories" in the checkbox input
    return (
        <div className="d-flex flex-column mb-3">
            {dropDown.displayLabel && <label htmlFor={dropDown.name} className="form-label">{dropDown.label}</label>}
            <div id={dropDown.name} className="mb-3 btn-group d-flex flex-column">
                <button className="btn btn-primary btn-lg dropdown-toggle form-control  col-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {`${dropDownValue?.length} ${dropDownValue?.length === 1 ? "category" : "categories"} selected`}
                </button>
                <ul className="dropdown-menu">
                    {dropDownList.map(category => {
                        return (
                            <li className="px-2" key={category.id}>
                                <div className="mb-1 form-check" >
                                    <input type="checkbox" className="form-check-input" id={`category-${category.id}`} name="categories" value={category.id} onChange={(event) => handleInputChange(event)} checked={dropDownValue?.length !== 0 && dropDownValue?.includes(category?.id)} />
                                    <label className="form-check-label" htmlFor={`category-${category.id}`} value={category.id}  >{category.name}</label>
                                </div>
                            </li>
                        );
                    })
                    }
                </ul>
            </div>
            {!isValid && <Error message={dropDown.error} />}
        </div>

    );
};

export default DropdownCheckboxField;