// external libraries
import React, { Fragment } from "react";

// custom component css
import "./index.css";

// this is common images component that can handle 
// - display of images on the product card in product listing
// - image display of the product page. However, this functinoality is not used as the product page is the product information edit page where all information is inputs
const AdminProductImages = ({ productDetails, showAdditional = true, mainOnly = false }) => {
    // getting images from the product details
    const { images } = productDetails;
    const noImage = "/media/products/no-product-image.jpg";

    // displaying the images
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-center align-items-center mb-3">
            <div id="productImageCarousel" className="d-flex flex-column justify-content-center align-items-center carousel slide col-12">

                <div className="carousel-inner ">
                    {/* main only is used for displaying only the main image in the product listing page where all products are displayed
                        - if no main image is present, it selects the first form the images array
                        - if no images are present, it default to no image
                        - on error in loading the image, the no image is displayee */}
                    {mainOnly ? <div className="carousel-item active ">
                        <img src={images?.find(image => image.isMain)?.url || images?.[0]?.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex col-12 img-fluid" alt="..." />
                    </div>
                        :
                        <Fragment>
                            {/* loads all images into a carousel for the product page
                                - if no images are present, it default to no image
                                - on error in loading the image, the no image is displayee 
                                NOTE: this will not be dtriggerd as the admin product page is input fields only */}
                            {images.map((image, index) => {
                                return (<div className={`carousel-item ${image.isMain && " active"} `} key={index}>
                                    <img src={image.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex col-12 img-fluid" alt={`Product illustration ${index + 1}`} />
                                </div>);
                            })}
                        </Fragment>
                    }
                </div>
                {/* showAdditional is for displaying the images thumbnails underneath the main image of the product page
                    NOTE: will not be triggered on the admin product page */}
                {showAdditional &&
                    <Fragment>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex carousel-indicators col-12 col-md-10 justify-content-start overflow-x-auto" style={{ height: "150px" }}>
                                {images.map((image, index) => {
                                    return (<button type="button" data-bs-target="#productImageCarousel" data-bs-slide-to={index} className="d-flex p-2 active h-auto w-auto" aria-current="true" aria-label="Slide 1" key={index}>
                                        <img src={image.url || noImage} onError={(e) => e.target.src = noImage} className="d-flex h-100 col-12 img-fluid" alt={`Product illustration ${index + 1}`} />
                                    </button>);
                                })}
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#productImageCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#productImageCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default AdminProductImages;