import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux"; 
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import CheckBox from "../FormFields/CheckBox";
import InputField from "../FormFields/InputField";

// redux slice
import { REGISTER_USER } from "../../../features/user/userMutations";
import { fetchUserData } from "../../../features/user/userSlice";

// utilities
import { emailFieldParams, firstNameFieldParams, lastNameFieldParams, passwordConfirmationFieldParams, passwordFieldParams, checkboxAgreedFieldParams } from "../../../utils/fieldParameters";
import auth from "../../../utils/auth";

// custom css
import './index.css';


// component holding the registration form so that a new user can register on the site
// when registering only the default user group (customer) is assigned
// an admin user must manually update the user to another user group - future release
// TODO: investigate using redux thunk to register user
// the fields used by the form must be defined in /utils/fieldParameters.js
const RegistrationForm = ({redirectTo}) => {
    const [formState, setFormState] = useState({ email: "", first_name: "", last_name: "", password: "", confirmation: "", agreed: false });
    const [RegistrationError, setRegistrationError] = useState({ status: false, message: "" });
    const [registration, { error }] = useMutation(REGISTER_USER);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    // handles changes to the registration form inputs
    const handleFormUpdate = (event) => {
        const { name, type, value, checked } = event.target;
        setFormState({
            ...formState,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // handles the form submit
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // user cannot process without agreeting to terms and conditions
        if (!formState.agreed) {
            setRegistrationError({
                status: true,
                message: "Please agree to the terms and conditions!"
            });
            return;
        }
        // password and confirmation must match
        if (formState.password !== formState.confirmation) {
            setRegistrationError({
                status: true,
                message: "Please ensure password and confirmation are the same"
            });
            return;
        }
        setLoading(true);
        // attempt to register
        try {
            const mutationResponse = await registration({
                variables: { email: formState.email, firstName: formState.first_name, lastName: formState.last_name, password: formState.password }
            });
            const token = mutationResponse.data?.registerUser?.token ?? null;
            if (token) {
                // display the products listing page on successful login
                setFormState({ email:"", first_name: "", last_name: "", password: "", confirmation: "", agreed: false});
                auth.login(token);
                dispatch(fetchUserData()).then(() => navigate(redirectTo));
                ;
            }

        } catch (error) {
            // display error message on unsuccessful attempt
            setRegistrationError({
                status: true,
                message: error.message
                
            });
            setLoading(false);
        }

    };

    // displays the form hoding the registration input fields
    return (
        <form className="d-flex flex-column col-12" onSubmit={handleFormSubmit}>
            <h5 className="fw-normal mb-3 pb-3 fs-3 text-primary" >Register for an account</h5>
            {RegistrationError.status && <div className="alert alert-danger">{RegistrationError.message}</div>}
            <InputField  value={formState.email} formUpdate={handleFormUpdate} {...emailFieldParams}  />

            <InputField value={formState.first_name} formUpdate={handleFormUpdate} {...firstNameFieldParams}/>

            <InputField value={formState.last_name} formUpdate={handleFormUpdate} {...lastNameFieldParams} />

            <InputField value={formState.password} formUpdate={handleFormUpdate} {...passwordFieldParams} />

            <InputField value={formState.confirmation} formUpdate={handleFormUpdate} {...passwordConfirmationFieldParams} />

            <CheckBox value={formState.agreed} formUpdate={handleFormUpdate} {...checkboxAgreedFieldParams} />

            <div className="pt-1 mb-4">
                <button className="btn btn-outline-primary btn-lg btn-block" type="submit">{ loading ? <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> : "Register" }</button>
            </div>

            <p className="mb-5 pb-lg-2" >Have an account? <Link to={"/login"} className="text-primary"
            >Log in here</Link></p>

        </form>);
};

export default RegistrationForm;

