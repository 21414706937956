// external libraries
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// privacy page taken direclty form kokabora.com
const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<main className="">
        <p>
            <br /><br />
            This Privacy Policy governs the manner in which Koka-Bora Creations ("Us", "We", or "Our") collects, uses, maintains, and discloses information collected from users (each, a "User") of the https://www.kokabora.com website ("Site"). This privacy policy applies to the Site and all products and services offered by Koka-Bora Creations.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Personal identification information</span>
        </h3>
        <p>
            We may collect personal identification information from Users in a variety of ways, including, but not limited to, register on the site, place an order, subscribe to the newsletter, and in connection with other activities, services, features or resources We make available on Our Site. We will collect personal identification information from Users only if the Users voluntarily submit such information to us. Users can always refuse to supply personal identification information and browse the Site anonymously. However, that may prevent Users from engaging in certain Site related activities, services, features or resources.<br />
            <br />
            To shop from the Site, Users may be asked for, as appropriate, name, email address, mailing and billing address. Users can shop only by registering an account: the personal information is saved in a password protected User account. Users can shop using this method for all physical and digital products.<br />
            <br />
            We <strong>DO NOT</strong> collect and store government identification information - Social Insurance Number, Social Security number, etc. - or credit card information. Entering this information on Our Site is strongly discouraged and the User bears all accountability, responsibility, and risks of doing so.<br />
            <br />
            In addition, all User information and all of Our products are accessed through www.kokabora.com. Our Site displays the web address at all times and if the web address <strong>DOES NOT</strong> contain kokabora.com in the beginning, then the Site is fraudulent. Entering any information on such fraudulent sites is strongly discouraged and the User bears all accountability, responsibility, and risks of doing so.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Non-personal identification information</span>
        </h3>
        <p>
            To enhance the User's experience on Our Site, We may collect non-personal identification information about Users whenever they interact with Our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to Our Site, such as the operating system and the Internet service providers utilized and other similar information.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">How We use collected information</span>
        </h3>
        <p>
            Koka-Bora Creations may collect and use Users' personal information for the following purposes:<br />
        </p>
        <li>To improve customer service: &nbsp;Information you provide helps Us respond to your customer service requests and support needs more efficiently.</li>
        <li>To personalize User experience: &nbsp;We may use information in the aggregate to understand how Our Users as a group use the services and resources provided on Our Site.</li>
        <li>To improve Our Site: &nbsp;We may use feedback you provide to improve Our products and services.</li>
        <li>To process payments: &nbsp; We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
        <li>To ship orders: &nbsp;We may use the information Users provide about them to ship their order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
        <li>To send periodic emails: &nbsp;We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests.</li>
        <li>To join Our newsletter: &nbsp;If User decides to opt-in to Our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, they can log into the account on Our Site and opt-out.</li><br />
        <h3>
            <span className="info text-primary">How We protect your information</span>
        </h3>
        <p>
            We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on Our Site.<br />
            <br />
            All personal information is stored in a password protected account on Our Site. The Users create the password during the registration process and is known only to them. Once the Users complete their transaction, Users can log out or close the browser to prevent access to their account.<br />
            <br />
            All information exchange between the Site and its Users - whether it is browsing the products or entering credit card information - happens over a Secured Socket Layer (SSL) communication channel and is encrypted and protected with digital signatures. Look for the closed lock icon on your web browser to verify the security.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Sharing your personal information</span>
        </h3>
        <p>
            We use outside delivery services (UPS and Canadapost) to ship orders and a credit card processing company (PayPal) to bill customers for goods and services. These companies do not retain, share, store or use personally identifiable information for any secondary purposes. Aside from that, We do not share, sell, trade, or rent Users' personal identification information to others.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Changes to this privacy policy</span>
        </h3>
        <p>
            Koka-Bora Creations has the discretion to update this privacy policy at any time. When We do, We will revise the updated date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes to stay informed about how We are helping to protect the personal information We collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Your acceptance of these terms</span>
        </h3>
        <p>
            By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use Our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.<br />
            <br />
        </p>
        <h3>
            <span className="info text-primary">Contacting Us</span>
        </h3>
        <p>
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please <Link to={"#"} className="text-primary" >Contact Us</Link><br />
            <br />
        </p>
        <p>
            This document was last updated on February 8, 2019<br />
            <br />
        </p>


    </main>);
};

export default Privacy;

