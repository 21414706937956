// external libraries
import React, { useEffect, useState } from "react";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";

// components
import BaseMenu from "../Base";
import MainOverlay from "./overlay";

// redux slice
import { selectCategories } from "../../../../features/ui/uiSelectors";


// recursive function drilling the categories and subcategories and returning an array of 
//  {
//      parentCategory: categoriy
//      children: [categories]
//  }
// each child category is an object of similar setup
const drillCategories = (currentCategory, categoryArray) => {
    const children = categoryArray.filter(catA => { return catA.parent !== null && catA.parent?.id === currentCategory.id; });

    categoryArray.splice(categoryArray.indexOf(currentCategory), 1);
    const currentCategoryWithoutParent = { ...currentCategory };
    delete (currentCategoryWithoutParent.parent);
    return {
        "category": currentCategoryWithoutParent,
        "children": children.length === 0 ? [] : children.map((child) => {
            return drillCategories(child, categoryArray);

        })
    };

};

// component creating the main menu comprising of all categories
// selecting on a category, the information is filtered based on products fitting that category
const MainMenu = () => {
    const categories = useSelector((state) => selectCategories(state));
    const[mainMenu, setMainMenu] = useState([]);

    useEffect(() => {
        if (categories) {
            const parentCategories = categories.filter((category) => category.parent === null);
            setMainMenu(parentCategories.map((parentCategory) => {
                return drillCategories(parentCategory, [...categories]);
            }));
        }
    }, [categories]);

    // creates a custom main menu that is supplied to the BaseMenu utilizing base menu's features for all menus (i.e. button positioning, buton spin on click, etc)
    return <BaseMenu icon={faBars} loading={!categories} overlay={<MainOverlay menu={mainMenu} /> } />;
};


export default MainMenu;

