import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import ProductImages from "../../../components/customer/Products/Common/Images";
import ProductInformation from "../../../components/customer/Products/Page/Info";
import { selectProductById } from "../../../features/products/productsSelectors";
import ProductDescription from "../../../components/customer/Products/Page/Description";


// Product page showing all details of a product
const Product = () => {
    const { id } = useParams();
    const productDetails = useSelector(state => selectProductById(state, id));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // if product is not found, redirect to the products page
    if (!productDetails) {
        return <Navigate to="/products" replace />;
    }

    // product page showing all details of a product including images, information, and description
    return (
        <main className="d-flex flex-column mb-4">
            <div className="d-flex flex-column flex-md-row flex-md-wrap justify-content-center align-items-start mx-2 border rounded shadow">
                <div className="d-flex flex-wrap col-12 ">
                    <div className="d-flex col-12 col-md-6">
                        <ProductImages productDetails={productDetails} />

                    </div>
                    <div className="d-flex col-12 col-md-6">
                        <ProductInformation productDetails={productDetails} />
                    </div>
                </div>
                <div className="d-flex  col-12 ">
                    <ProductDescription productDetails={productDetails} />

                </div>
            </div>
        </main>
    );
};

export default Product;