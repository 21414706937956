// external libraries
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import Cookie from 'js-cookie';

// creating the link to graphql that handles file uploads
const httpLink = createUploadLink({
    uri: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/graphql/`,
    credentials: "include"
});

// setting the authentication context in the headers
// - sending the JWT authentication token 
// - sending the CSRF tokens in the header
// both are validated at the back end to ensure the user is valid user
const authLink = setContext((_, { headers }) => {
    const jwtToken = sessionStorage.getItem('id_token');
    return {
        headers: {
            ...headers,
            authorization: jwtToken ? `Bearer ${jwtToken}` : '',
            "X-CSRFToken": Cookie.get("csrftoken")
        },
    };
});

// setting up and exporting the apollo client
// - addTypename = false in order to remove the __typename attribute from the graphql responses
// - connectToDevTools = true for debugging purposes. 
export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false
    }
    ),
    connectToDevTools: true
});

