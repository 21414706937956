// external libraries
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// redux slice
import { selectUserIsStaff } from '../../../features/user/userSelectors';


// admin route wrapper to ensure only the staff users have access to the information contained in the route
const AdminRoute = ({ children }) => {
    const userIsStaff = useSelector(state => selectUserIsStaff(state));
    const location = useLocation();

    if (userIsStaff) {
        return children;
    } else {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
};

export default AdminRoute;
