// external libraries
import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useLocation } from "react-router-dom";

// components
import ProductsContainer from "../../../components/customer/Products/Dashboard/List";
import { SUCCESS, LOADING, LOGIN_ERROR } from "../../../features/status";
import { selectAllWishlistProductDetails } from "../../../features/wishlist/wishlistSelectors";

// users wishlist page showing all products in the user's wishlist
const WishlistPage = () => {
    const productsLoaded = useSelector(state => state.products.status === SUCCESS);
    const loading = useSelector((state) => state.user.status === LOADING);
    const loginError = useSelector((state) => state.wishlist.status === LOGIN_ERROR || state.user.status === LOGIN_ERROR || state.ui.globalStatus === LOGIN_ERROR);
    const productsInWishlist = useSelector((state) => selectAllWishlistProductDetails(state));
    const location = useLocation();
    // controls the display of information in the product card in the wishlist page
    const display = {
        quantity: {
            component: false,
            buttons: false,
            disabled: false
        },
        cart: {
            add: true,
            update: false
        },
        wishlist: false,
        remove: {
            cart: false,
            wishlist: true
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loginError) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    if (!productsLoaded) {
        return <Navigate to="/products" replace />;
    }

    // users wishlist page showing all products in the user's wishlist
    return (
        <main className="d-flex flex-column">
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-start my-5 mx-2 gap-2 ">
                <div className="d-flex flex-column col-12 col-lg-8">
                    <ProductsContainer loading={loading} icon={faHeart} title={"Wishlist"} products={productsInWishlist} display={display} />
                </div>
            </div>

        </main>
    );
};

export default WishlistPage;

