// external libraries
import React from "react";

// components
import ProductPageMessage from "../../../components/common/Messages/ProductPage";
import ProductList from "../../../components/customer/Products/Display/List";


// product listing page showing all products
const Products = () => {
    return (<main className="d-flex flex-column">
        <ProductPageMessage />
        <ProductList title="" maxCount={false} applyFilter={true} />
    </main>);
};

export default Products;

