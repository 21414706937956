// external libraries
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";


// compoenent interating over the menus provided to create a UL element that will be returned and supplied to the BaseMenu for display
const AccountOverlay = ({ menu }) => {
    const disabled = false;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <ul className="d-flex dropdown-menu border-0 bg-transparent px-2 flex-column fs-4 list-group">
            {menu.map((item, index) => {
                return (
                    <li className="d-flex list-group-item gap-2 justify-content-end border-0 bg-transparent" key={index}>
                        <button className="btn fs-4 text-primary" type="button" data-bs-toggle="" aria-expanded="false" disabled={disabled} onClick={() => navigate(item.url, { state: { from: location } })} >
                            {item.menu}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

export default AccountOverlay;