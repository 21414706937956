// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// providers
import { client } from '../../app/apollo';

// redux slice
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { fetchUserData } from '../user/userSlice';
import { QUERY_STRIPE_SESSION } from './stripeQueries';


// creating stripe entity adapter as per redux documentation
export const stripeAdapter = createEntityAdapter();

// initial state of the stripe slice
const initialState = stripeAdapter.getInitialState({
    status: IDLE,
    error: null,
});


// async thunk to get the payment details from stripe
// fetch policy is 'cache-first' so that the data is fetched from the cache if it exists (i.e. user is querying the same session)
export const getSessionDetails = createAsyncThunk(
    'stripe/getPaymentDetails',
    async (sessionId, { rejectWithValue }
    ) => {
        try {
            const response = await client.query({
                query: QUERY_STRIPE_SESSION,
                variables: { sessionId: sessionId },
                fetchPolicy: 'cache-first'
            });
            // graphene is returning a jsonstring so we need to parse it to json object
            const sessionDetails = JSON.parse(response?.data?.sessionDetails);
            sessionDetails.id = sessionId;
            if (!response?.data?.sessionDetails) {
                return rejectWithValue({ "login_error": true });
            }

            return sessionDetails;
        } catch (error) {
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });

// creating the stripe slice based on redux doucmentation
const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        stripeReset(state, action) {
            return initialState;
        },

    },
    extraReducers(builder) {
        builder
            // after fetchUserData is successful, each component goes into it's own slice. the cart goes into the cart slice
            // if the fetchUserData is rejected, the intital state is returned
            .addCase(fetchUserData.rejected, (state, action) => {
                return { ...initialState };
            })
            // fetching the payment detail information form stripe:
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and the new payment information is added to the slice
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(getSessionDetails.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(getSessionDetails.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;


                stripeAdapter.upsertOne(state, action?.payload || {});
            })
            .addCase(getSessionDetails.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {...initialState, 
                    status: login_error ? LOGIN_ERROR : FAILED,
                    error: login_error ? LOGIN_ERROR : FAILED

                };
            });
    },
});

// exporting the stripe actions
export const { stripeReset } = stripeSlice.actions;

// exporting the stripe reducer
export default stripeSlice.reducer;

