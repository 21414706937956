// external libraries
import React from "react";
import { faShoppingCart, faHeartCirclePlus, faCircleInfo, faCartPlus, faHeart } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import PriceInformation from "../../Common/PriceInfo";
import Loading from "../../../../common/Loading";
import ActionButton from "../../../ActionButton";
import ProductImages from "../../Common/Images";

// redux slice
import { LOADING, LOGIN_ERROR } from "../../../../../features/status";
import { addItemToCart } from "../../../../../features/cart/cartSlice";
import { selectCartProductIds } from "../../../../../features/cart/cartSelectors";
import { selectWishlistProductIds } from "../../../../../features/wishlist/wishlistSelectors";
import { updateWishlist } from "../../../../../features/wishlist/wishlistSlice";


// this component displays the product card on the product display page; the page visible to all customers even if they are not logged in. This is different product card from the one displayed on the dashboard
const DisplayProductCard = ({ product }) => {
    const isLoading = useSelector((state) => state.products.status === LOADING);
    const loginRedirect = useSelector(state => state.cart.status === LOGIN_ERROR || state.wishlist.status === LOGIN_ERROR);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const shoppingCartIcon = useSelector(state => selectCartProductIds(state).includes(product.id)) ? faCartPlus : faShoppingCart;
    const wishlistIcon = useSelector(state => selectWishlistProductIds(state).includes(product.id)) ? faHeartCirclePlus : faHeart;

    // the product cart page has three buttons at the front
    // - add to cart- default to adding 1 to the cart quantity total. Clicking several times will increase the quantity total
    // use has visual clue when product is added to the cart
    const handleCartClick = async (productId) => {
        dispatch(addItemToCart([
            {
                "id": productId,
                "quantity": null
            }
        ]));
    };

    // - add to wishlist - adds the product to the wishlist
    // use has visual clue when product is added to the wishlist
    const handleWishlistClick = async (productId) => {
        dispatch(updateWishlist([
            {
                "id": productId
            }
        ]));
    };

    // - details - navigates to the product details page
    const handleDetailsClick = (productId) => {
        navigate(`/products/${productId}`);
    };

    // if the global state is still loading, display the loading component
    if (isLoading) {
        return <section className={`d-flex card col-12 p-2 justify-content-between shadow-sm`} >
            <Loading />
        </section>;
    }

    // if there is a login error, redirect to the login page this is in cases where user is not logged in but want to add items to cart or wishlist
    if (loginRedirect) {
        return <Navigate to="/login" replace state={{ from: location.pathname }} />;
    }

    // returns the product card component
    return (

        <section className={`d-flex card col-12 p-2 justify-content-between shadow-sm`} >
            <div className="d-flex flex-column justify-content-start card-body ">
                {/* main only image */}
                <ProductImages productDetails={product} showAdditional={false} mainOnly={true} />
                <div className="btn-group col-12" role="group" >
                    {/* action buttons */}
                    <div className="col-4">
                        <ActionButton icon={shoppingCartIcon} text={""} primary={true} onClick={() => handleCartClick(product?.id)} disabled={isLoading} />
                    </div>
                    <div className="col-4">
                        <ActionButton icon={wishlistIcon} text={""} primary={false} onClick={() => handleWishlistClick(product?.id)} disabled={isLoading} />
                    </div>
                    <div className="col-4">
                        <ActionButton icon={faCircleInfo} text={""} primary={false} onClick={() => handleDetailsClick(product?.id)} disabled={isLoading} />
                    </div>
                </div>
                {/* product name */}
                <div className="d-flex">
                    <div className="d-flex col-12 flex-column justify-content-center align-items-center ">

                        <div className={`d-flex mb-1 fs-5 text-center text-uppercase text-decoration-none text-secondary `}>{product?.name}</div>

                    </div>
                </div>
            </div>
            {/* price information for the user group */}
            <div className="d-flex justify-content-center">
                <PriceInformation price={product?.pricing?.[0] || []} />
            </div>

        </section>
    );
};

export default DisplayProductCard;




