// external libraries
import React, { Fragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// redux slice
import { LOADING, SUCCESS } from '../../../../features/status';
import { selectUserId } from '../../../../features/user/userSelectors';

// utilities
import auth from '../../../../utils/auth';


// user must be logged in to access the route
const ProtectedRoute = ({ children }) => {
  // const loggedIn = useSelector(state => selectUserIsLoggedIn(state));
  const loading = useSelector(state => state.user.status === LOADING);
  const success = useSelector(state => state.user.status === SUCCESS);
  const location = useLocation();
  const loggedIn = auth.loggedIn(useSelector(state => selectUserId(state)));

  // if the application is authenticating the user, the child component's loading ui will be displayed
  if (loading) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  // on successful log in, user sees the protected route
  // otherwise the user is redirected to the login page
  return (
    <Fragment>
      {success && !loggedIn ? <Navigate to={"/login"} replace state={{ from: location }} /> : children}
    </Fragment>
  );

};

export default ProtectedRoute;
