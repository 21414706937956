// external libraries
import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';

// providers
import { client } from '../../app/apollo';

// redux slice
import { QUERY_ADMIN_UI_SETTINGS, QUERY_UI_SETTINGS } from './uiQueries';
import { LOADING, SUCCESS, IDLE, FAILED, LOGIN_ERROR } from '../status';

// creating inital state
const initialState = {
    menuOpened: false,
    categories: [],
    menuStatus: IDLE,
    globalStatus: IDLE,
    userGroups: [],
    length: [],
    weight: [], 
    currencies: []
};

// async thunk to fetch the UI settings
export const fetchUISettings = createAsyncThunk('ui/fetchUISettings',
    async () => {
        try {
            const response = await client.query({
                query: QUERY_UI_SETTINGS,
                variables: {}
            });
            return response;
        } catch (error) {
            console.log(error);
        }
    }
);

// async thunk to fetch the admin UI settings
export const fetchAdminUISettings = createAsyncThunk('ui/fetchAdminUISettings',
    async () => {
        try {
            const response = await client.query({
                query: QUERY_ADMIN_UI_SETTINGS,
                variables: {}
            });
            return response;
        } catch (error) {
            console.log(error);
        }
    }
);


// creating slice based on documentation; entity adapter is not required as we do not have lists of entities
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleMenuOpened(state, action) {
            state.menuOpened = !state.menuOpened;
        },
        toggleMenuIconAction(state, action) {
            state.menuIconAction = action.payload;
        },
        toggleMenuOverlayAction(state, action) {
            state.menuOverlayAction = action.payload;
        },
        globalStatusReset(state, action) {
            state.globalStatus = IDLE;
        },
        setLoading(state) {
            state.globalStatus = LOADING;
        },
        setFulfilled(state) {
            state.globalStatus = SUCCESS;
        },
        setError(state) {
            state.globalStatus = FAILED;
        }, 
        setLoginError(state) {
            state.globalStatus = LOGIN_ERROR;
        },
        uiReset(state) {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            // fetches the UI settings for customer and staff users; if fulfilled, enter data in the state
            .addCase(fetchUISettings.pending, (state, action) => {
                state.menuStatus = LOADING;
                state.error = null;
            })
            .addCase(fetchUISettings.fulfilled, (state, action) => {
                state.menuStatus = SUCCESS;
                state.error = null;

                // Add any fetched settings to the state
                state.categories = action?.payload?.data?.categories || [];
                state.length = action?.payload?.data?.length || [];
                state.weight = action?.payload?.data?.weight || [];
                state.currencies = action?.payload?.data?.currencies || [];
            })
            .addCase(fetchUISettings.rejected, (state, action) => {
                state.menuStatus = FAILED;
                // state.error = action.payload.error
            })
            // fetches the UI settings for admin users; if fulfilled, enter data in the state
            .addCase(fetchAdminUISettings.pending, (state, action) => {
                state.menuStatus = LOADING;
                state.error = null;

            })
            .addCase(fetchAdminUISettings.fulfilled, (state, action) => {
                state.menuStatus = SUCCESS;
                state.error = null;

                // Add any fetched products to the array
                state.userGroups = action?.payload?.data?.customerGroups || [];

            })
            .addCase(fetchAdminUISettings.rejected, (state, action) => {
                state.menuStatus = FAILED;
                // state.error = action.payload.error
            });
    },

});

// exporting the actions
export const { toggleMenuOpened, setLoading, setFulfilled, setError, setLoginError, globalStatusReset } = uiSlice.actions;

// exporting the reducer
export default uiSlice.reducer;

