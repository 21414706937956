// external libraries
import { gql } from '@apollo/client';

// graphql query to create an order that belongs to the logged in user
// this is done after the user pays from stripe
export const MUTATION_CREATE_ORDER = gql`
mutation CreateOrder($products: [CartProductInputType], $sessionId: String) {
  upsertOrder(orderData: {sessionId: $sessionId, products: $products}) {
    order {
      id
      sessionId
      createdAt
      products {
        quantity
        product {
          id
        }
      }
    }
  }
}

`;