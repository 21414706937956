// external libraries
import React from "react";

// utilities
import { formatCurrency } from "../../../../../utils/numberFormats";

// component that displays the price information of the product
// - if the product has special price, it will display the regular price with a line through it and below it the special price
// - if the product does not have a special price, it will only display the regular price
const PriceInformation = ({price}) => {
    const { regular, special } = price;
    return (
        <div className="d-flex flex-column mb-3 p-3 justify-content-evenly align-items-center border rounded shadow ">
            {special ?
                <div className={`d-flex text-decoration-line-through text-secondary fs-6 `}>
                    { formatCurrency(regular)}
                </div>
                : ''
            }
            <div className={`d-flex text-primary fs-4 `}>
                { formatCurrency(special ? special : regular)}
            </div>
        </div>
    );
};

export default PriceInformation;