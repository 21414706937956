// external libraries
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";

// components
import ProductsContainer from "../../../components/customer/Products/Dashboard/List";
import OrderSelector from "../../../components/customer/Dashboard/OrderSelector";
import OrderSummary from "../../../components/customer/Dashboard/OrderSummary";

// redux slice
import { SUCCESS, LOADING, LOGIN_ERROR } from "../../../features/status";
import { selectOrderById } from "../../../features/orders/ordersSelectors";
import { getSessionDetails } from "../../../features/stripe/stripeSlice";
import { selectStripeSessionInfoById } from "../../../features/stripe/stripeSelectors";


// customers orders page alloing the user to view and order and get all details including the products
const Orders = () => {
    const selectedOrder = useSelector(state => selectOrderById(state, state?.orders?.selectedOrder || ""));
    const selectedOrderDetails = useSelector(state => selectStripeSessionInfoById(state, selectedOrder?.sessionId || ""));
    const productsLoaded = useSelector(state => state.products.status === SUCCESS);
    const loading = useSelector((state) => state.user.status === LOADING || state.stripe.status === LOADING);
    const dispatch = useDispatch();
    const location = useLocation();
    const loginError = useSelector(state => state.stripe.status === LOGIN_ERROR || state.user.status === LOGIN_ERROR || state.orders.status === LOGIN_ERROR || state.ui.globalStatus === LOGIN_ERROR);
    // display settings for the products in the order; this controls what is displayed in the product card in the orders page
    const display = {
        quantity: {
            component: true,
            buttons: false,
            disabled: true,
            times: true
        },
        cart: {
            add: false,
            update: false
        },
        wishlist: false,
        remove: {
            cart: false,
            wishlist: false
        }
    };

    // on every change in the selectedOrder, get the session details
    useEffect(() => {
        if (selectedOrder) {
            dispatch(getSessionDetails(selectedOrder.sessionId));
        }
    }, [dispatch, selectedOrder]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // if user is not logged in, redirect to login
    if (loginError) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    // if products are not loaded, redirect to products page
    if (!productsLoaded) {
        return <Navigate to="/products" replace />;
    }

    // user's orders page showing all orders the user have and subsequently getting the information about the order
    return (
        <main className="d-flex flex-column">
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-start my-5 mx-2 gap-2 ">
                <div className="d-flex flex-column col-12 col-lg-4">
                    <OrderSelector />
                    <OrderSummary loading={loading} orderDetails={selectedOrderDetails?.session || {}} />
                </div>
                <div className="d-flex flex-column col-12 col-lg-8">
                    <ProductsContainer loading={loading} icon={faBoxes} title={"Order Product Details"} products={selectedOrderDetails?.products || []} display={display} />
                </div>
            </div>

        </main>
    );
};

export default Orders;

