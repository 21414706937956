// extertnal libraries
import { createSelector } from "@reduxjs/toolkit";

// redux slice
import { ordersAdapter } from "./ordersSlice";

// default selectors offered by redux toolkit
export const {
    selectAll: selectAllOrders,
    selectById: selectOrderById,
    selectIds: selectOrderIds,
} = ordersAdapter.getSelectors((state) => state.orders);

// selects the product ids and flattens the structure so that we only get the product id
export const orderProductIds = createSelector(
    (state, orderId) => selectOrderById(state, orderId),
    (order) => {
        // product is a product type so it appears as a subsequent object as in products[0].product.id
        // the code below will flatten it so that it appears as products[0].id
        order = {
            ...order,
            products: order?.products.map((product) => {
                return product.product.id;
            })
        };
        return order;
    }
);

// returns the order products from the state in a flattended format removing the extra product attribute {id: product.id, quantity: product.quantity}
export const selectOrderProducts = createSelector(
    (state, orderId) => selectOrderById(state, orderId),
    (order) => {
        // product is a product type so it appears as a subsequent object as in products[0].product.id
        // the code below will flatten it so that it appears as products[0].id
        order = {
            ...order,
            products: order?.products.map((product) => {
                return {id: product.product.id, quantity: product.quantity};
            })
        };
        return order;
    }
);