import React, { useState, useEffect } from "react";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";

// components
import BaseMenu from "../Base";
import AccountOverlay from "./overlay";

// redux slice
import { LOADING } from "../../../../features/status";


// component that builds the account menu depending on the user group of the logged in user
// customers get customer dashboard menus
// admin gets admin dashboard (currently only logout)
// non logged in users get the login or register
const AccountMenu = () => {
    const loading = useSelector(state => state.user.status === LOADING);
    const isCustomer = useSelector(state => state.user.isCustomer);
    const isStaff = useSelector(state => state.user.isStaff);
    const[accountMenu, setAccountMenu] = useState([]);

    // building the menu based on user group. There is a potential of having database driven menu selection for the various user groups, however, that's future release
    useEffect(() => {
        let menu = [];
        if (isCustomer) {
            menu = [
                {
                    "menu": "Cart",
                    "url": "/cart"
                },
                {
                    "menu": "Wishlist",
                    "url": "/wishlist"                    
                },
                {
                    "menu": "Orders",
                    "url": "/orders"
                },
                {
                    "menu": "Logout",
                    "url": "/logout"
                },
            ];
        } else if (isStaff) {
            menu = [
                {
                    "menu": "Logout",
                    "url": "/logout"
                },
            ];
        } else {
            menu = [
                {
                    "menu": "Login",
                    "url": "/login"
                },
                {
                    "menu": "Register",
                    "url": "/register"                    
                }
            ];

        }

        setAccountMenu(menu);
    }, [isCustomer, isStaff]);
   
    // creates a custom AccountOverlay of the account menus that is supplied to the BaseMenu utilizing base menu's features for all menus (i.e. button positioning, buton spin on click, etc)
    return <BaseMenu icon={faUser} loading={loading} overlay={<AccountOverlay menu={accountMenu}/>} />;
};

export default AccountMenu;

