// external libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { faBoxesStacked, faHeart, faHeartCirclePlus, faRefresh, faRuler, faRulerCombined, faRulerHorizontal, faRulerVertical, faScaleUnbalanced, faShoppingCart, faTag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// components
import PriceInformation from "../../Common/PriceInfo";
import ActionButton from "../../../ActionButton";
import QuantityInput from "../../Common/QuantityInput";

// redux slice
import { addItemToCart } from "../../../../../features/cart/cartSlice";
import { LOGIN_ERROR } from "../../../../../features/status";
import { updateWishlist } from "../../../../../features/wishlist/wishlistSlice";
import { selectCartProductById } from "../../../../../features/cart/cartSelectors";
import { selectWishlistProductById } from "../../../../../features/wishlist/wishlistSelectors";

// utilities
import { formatDimensionScale } from "../../../../../utils/numberFormats";

const ProductInformation = ({ productDetails }) => {
    // checks if the product is added to the cart and display the add button accordingly
    const productCartInformation = useSelector(state => selectCartProductById(state, productDetails.id));
    const cartButton = {
        icon: productCartInformation ? faRefresh : faShoppingCart,
        text: productCartInformation ? "Update Cart" : "Add to Cart"
    };
    // checks if the product is added to the cart and display the wishlist toggle button accordingly
    const productWishlistInformation = useSelector(state => selectWishlistProductById(state, productDetails.id));
    const wishlistButton = {
        icon: productWishlistInformation ? faHeartCirclePlus : faHeart,
        text: productWishlistInformation ? "Remove from Wishlist" : "Add to Wishlist"
    };
    // the remove button
    const cartDeleteButton = {
        icon: faTrash,
        text: "Remove from Cart"
    };
    const loginError = useSelector(state => state.cart.status === LOGIN_ERROR || state.wishlist.status === LOGIN_ERROR);
    const location = useLocation();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(productCartInformation?.quantity || 1);

    // updates the quantity or deletes the item if deleteItem = true
    const handleCartUpdate = (deleteItem = false) => {
        dispatch(addItemToCart([
            {
                "id": productDetails.id,
                "quantity": deleteItem ? 0 : quantity
            }
        ]));

    };
    // adds product to wishlist
    const handleWishlistUpdate = () => {
        dispatch(updateWishlist([
            {
                "id": productDetails.id
            }
        ]));

    };

    // if thre's a login error, redirect to the login page these are cases where user tries to update cart or wishlist when the JWT has just expired
    if (loginError) {
        return <Navigate to="/login" replace state={{ from: location.pathname }} />;
    }

    // the main component that displays the product information in summary form
    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start mb-3">
            <h2 className="mb-5 text-primary fs-1">{productDetails.name}</h2>
            {/* product details like sku, details, weight, etc */}
            <div id="information" className="mb-3">
                <h3 className="mb-1 text-secondary fs-4 mb-3">Information</h3>
                <ul className="list-unstyled">
                    <li className="mb-1 fs-5"><FontAwesomeIcon icon={faTag} /> Product Code: <span className="text-primary">{productDetails.sku}</span></li>
                    <li className="mb-1 fs-5"><FontAwesomeIcon icon={faBoxesStacked} /> Availability: <b className="stock text-primary">In Stock</b></li>
                    <li className="mb-1 fs-5"><FontAwesomeIcon icon={faScaleUnbalanced} /> Weight: <span className="text-primary">{formatDimensionScale(productDetails.weight)}</span></li>
                    <li className="mb-1 fs-5"><FontAwesomeIcon icon={faRulerCombined} /> Dimensions:
                        <ul className="list-unstyled">
                            <li className="mb-1 fs-5 ms-5"><FontAwesomeIcon icon={faRulerHorizontal} /> Length: <span className="text-primary">{formatDimensionScale(productDetails.length)}</span></li>
                            <li className="mb-1 fs-5 ms-5"><FontAwesomeIcon icon={faRuler} /> Width: <span className="text-primary">{formatDimensionScale(productDetails.width)}</span></li>
                            <li className="mb-1 fs-5 ms-5"><FontAwesomeIcon icon={faRulerVertical} /> Height: <span className="text-primary">{formatDimensionScale(productDetails.height)}</span></li>
                        </ul>
                    </li>
                </ul>
            </div>
            {/* product price for the user group the user belongs to */}
            <div id="price" className="d-flex justify-content-start mb-5">
                <PriceInformation price={productDetails?.pricing?.[0] || []} />
            </div>
            {/* product action buttons where the user can update quantity etc */}
            <div id="cart_handler" className="options d-flex ">
                <div className="cart">
                    <div className="quantily_info">
                        <QuantityInput quantity={quantity} updateQuantity={setQuantity} />
                    </div>
                    <div className="button-group ">
                        <ActionButton icon={cartButton.icon} text={cartButton.text} onClick={handleCartUpdate} primary={true} disabled={quantity === productCartInformation?.quantity} />

                        {productCartInformation &&
                            <ActionButton icon={cartDeleteButton.icon} text={cartDeleteButton.text} onClick={() => {
                                handleCartUpdate(true);
                                setQuantity(1);
                            }} />}

                        <ActionButton icon={wishlistButton.icon} text={wishlistButton.text} onClick={handleWishlistUpdate} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProductInformation;