// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// providers
import { client } from '../../app/apollo';

// redux slice
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { MUTATION_ADD_TO_WISHLIST } from './wishlistMutations';
import { fetchUserData } from '../user/userSlice';

// creating wishlist entity adapter as per redux documentation
export const wishlistAdapter = createEntityAdapter();

// initial state of the wishlist slice
const initialState = {
    status: IDLE,
    error: null,
    id: "",
    products: []
};


// async thunk to update the wishlist
export const updateWishlist = createAsyncThunk(
    'wishlist/updateWishlist',
    async (products, { rejectWithValue }
    ) => {
        try {
            const response = await client.mutate({
                mutation: MUTATION_ADD_TO_WISHLIST,
                variables: {products: products},
                fetchPolicy: 'network-only'
            });
            if (!response?.data?.upsertWishlist?.wishlist) {
                return rejectWithValue({"login_error":true});
            }


            return response;
        } catch (error) {
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });


// creating the wishlist slice based on redux doucmentation
const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        wishlistReset(state, action) {
            return initialState;
        },

    },
    extraReducers(builder) {
        builder
            // after fetchUserData is successful, each component goes into it's own slice. the cart goes into the cart slice
            // if the fetchUserData is rejected, the intital state is returned            
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.id = action?.payload?.data?.wishlist?.id || "";
                state.products = action?.payload?.data?.wishlist?.products || [];
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                return {...initialState};
            })
            // updating the wishlist
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and the wishlist is updated 
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(updateWishlist.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(updateWishlist.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.error = null;

                state.id = action?.payload?.data?.upsertWishlist?.wishlist.id || "";
                state.products = action?.payload?.data?.upsertWishlist?.wishlist.products || [];
            })
            .addCase(updateWishlist.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {
                    ...initialState,
                    status: login_error ? LOGIN_ERROR : FAILED,
                    error: login_error ? LOGIN_ERROR : FAILED
                };
            });
    },
});

// exporting actions
export const { wishlistReset } = wishlistSlice.actions;

// exporting the wishlist reducer
export default wishlistSlice.reducer;

