//  external libraries
import React, { useState, } from "react";

// components
import Error from "../Error";

// input field that presents the user with a text box for information entry
// the input must be defined in the /utils/fieldParameters.js
// the component sends the event directly to update the formState as there are no additional components providing input
const InputField = ({ value, formUpdate, name, type, placeholder, validationRegex, displayLabel, label, displayError, error, displayTooltip, tooltip }) => {
    const [isValid, setIsValid] = useState(true);

    const validate = (value) => {
        return validationRegex.test(value);
    };

    const handleInputChange = (event) => {
        formUpdate(event);
        setIsValid(validate(event.target.value));
    };

    return (
        <div className="d-flex flex-column mb-3">
            {displayLabel && <label htmlFor={name} className="form-label">{label}</label>}
            {tooltip && <small id={`${name}Help`} className="alert alert-warning text-muted" dangerouslySetInnerHTML={{__html: tooltip}} />}
            <input type={type} className="form-control form-control-lg mb-2" id={name} name={name} aria-describedby={`${name}Help`} placeholder={placeholder}
                value={value}
                onChange={handleInputChange} 
            
            />
            {!isValid && <Error message={error} />}
        </div>
    );
};

export default InputField;