// external libraries
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// components
import InputField from "../../../../common/FormFields/InputField";
import InputDropdownField from "../../../../common/FormFields/InputDropdown";
import DropdownCheckboxField from "../../../../common/FormFields/DropdownCheckbox";
import AdminPriceInput from "../../Common/PriceInput";

// redux slice
import { LOGIN_ERROR } from "../../../../../features/status";
import { selectCustomerGroups } from "../../../../../features/ui/uiSelectors";

// utilities
import {
    productCategoryCheckboxFieldParams,
    productCategoryDropdownFieldParams,
    productNameFieldParams,
    productSKUFieldParams,
    productWeightScaleFieldParams,
    productWeightValueFieldParams,
    productLengthScaleFieldParams,
    productLengthValueFieldParams,
    productWidthScaleFieldParams,
    productWidthValueFieldParams,
    productHeightScaleFieldParams,
    productHeightValueFieldParams,
} from "../../../../../utils/fieldParameters";


// component that displays all input fields for product name, product categories, weight, and price
// the length, width, and height are work in progress
const AdminProductInformation = ({ onChange, formState }) => {
    const categories = useSelector(state => state.ui.categories);
    const weight = useSelector(state => state.ui.weight);
    const length = useSelector(state => state.ui.length);
    const userGroups = useSelector(state => selectCustomerGroups(state));
    const loginError = useSelector(state => state.cart.status === LOGIN_ERROR || state.wishlist.status === LOGIN_ERROR);
    const location = useLocation();
    
    if (loginError) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return (
        <div className="d-flex flex-column col-12 p-2 justify-content-start mb-3">
            <h3 className="mb-1 text-secondary fs-4 mb-3">Product Information</h3>
            <div className="d-flex flex-column flex-wrap col-12">
                <InputField {...productNameFieldParams} value={formState?.[0]?.name} formUpdate={onChange} />
                <DropdownCheckboxField dropDown={productCategoryDropdownFieldParams} formUpdate={onChange} checkbox={productCategoryCheckboxFieldParams} dropDownValue={formState?.[0]?.categories} dropDownList={categories} formState={formState } />
            </div>
            <div id="information" className="mb-3">

                <InputField {...productSKUFieldParams} value={formState?.[0]?.sku} formUpdate={onChange} />
                <InputDropdownField input={productWeightValueFieldParams} inputValue={formState?.[0]?.weight?.value} formUpdate={onChange} dropDown={productWeightScaleFieldParams} dropDownValue={formState?.[0]?.weight?.scale?.code} dropDownList={weight} formState={formState} />
                <InputDropdownField input={productLengthValueFieldParams} inputValue={formState?.[0]?.length?.value} formUpdate={onChange} dropDown={productLengthScaleFieldParams} dropDownValue={formState?.[0]?.length?.scale?.code} dropDownList={length} formState={formState} />
                <InputDropdownField input={productWidthValueFieldParams} inputValue={formState?.[0]?.width?.value} formUpdate={onChange} dropDown={productWidthScaleFieldParams} dropDownValue={formState?.[0]?.width?.scale?.code} dropDownList={length} formState={formState} />
                <InputDropdownField input={productHeightValueFieldParams} inputValue={formState?.[0]?.height?.value} formUpdate={onChange} dropDown={productHeightScaleFieldParams} dropDownValue={formState?.[0]?.height?.scale?.code} dropDownList={length} formState={formState} />

            </div>
            <div id="price" className="d-flex flex-column justify-content-start mb-5">
                {
                    userGroups.map(userGroup => {

                        const userPricing = formState?.[0].pricing?.find(price => price?.userGroup?.id === userGroup.id) || {};

                        return <AdminPriceInput price={userPricing} formUpdate={onChange} key={userGroup.id} formState={formState} userGroup={ userGroup } />;
                    })
                }
            </div>


        </div>
    );
};

export default AdminProductInformation;