// external librareis
import { gql } from '@apollo/client';

// query to get the uer's information including wishilist, cart, orders and user data
export const QUERY_USER_DATA = gql`
query GetUserData {
  wishlist {
    id
    products {
      id
    }
  }
	cart {
    id
		products {
			product {
				id
			}
      quantity
		}
	}
  orders {
    id
    sessionId
    createdAt
    products {
      product {
        id
      }
      quantity
    }
  }
  userData {
    id
    isCustomer
    isStaff
    isLoggedIn
  }
}`;

