// external libraries
import React, {useEffect} from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// components
import AdminProductList from "../../../components/admin/Products/Display/List";
import AdminActionButton from "../../../components/admin/ActionButton";

// AdminProducts page - this is the page where the admin can see all the products
const AdminProducts = () => {
    const navigate = useNavigate();

    const handleNewProduct = () => {
        navigate("/admin/products/new");
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<main className="d-flex flex-column">
        <div className="d-flex px-3 py-2 position-fixed z-3">
            <AdminActionButton icon={faPen} text={"New Product"} primary={true} onClick={() => handleNewProduct()} />
        </div>
        <AdminProductList title="" maxCount={false} applyFilter={false} />
    </main>);
};

export default AdminProducts;

