// external libraries
import React from "react";
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";

// components
import BaseMenu from "../Base";
import SettingsOverlay from "./overlay";

// redux slice
import { selectMeasurements } from "../../../../features/ui/uiSelectors";

// settings menu where the user can change the dimensions or weight scale
const SettingsMenu = () => {
    const dataUI = useSelector((state) => selectMeasurements(state));

    // creates a custom overlay that is used by the base menu to render to the user; closeOnClick is set to false as the user can opt to update several settings before closing the menu
    return <BaseMenu icon={faGear} loading={!dataUI} overlay={<SettingsOverlay settings={dataUI} />} closeOnClick={false} />;
};


export default SettingsMenu;

