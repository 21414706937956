// external libraries
import React, { useState, } from "react";

// components
import Error from "../Error";


// a component that holds together both an input followed by dropdown.
// this component is used in cases of the dimension input. i.e. user enters value and scale. the scale consist of two metric and 2 imperial measurements
// information about the input and drowdown must be defined in the /utils/fieldParameters.js
// the dimension object consists of the value, and scale object. Scale object contains the scale code and name
// the dimention fiels are separated by hyphen (-) to ease the parsing and update ofthe component
// the component is responsible for the update fothe value, which are then sent to update the formState
const InputDropdownField = ({ inputValue, formUpdate, input, dropDown, dropDownValue, dropDownList, formState }) => {
    const [isValid, setIsValid] = useState(true);

    const validate = (value, field) => {
        return field.validationRegex.test(value);
    };

    const handleInputChange = (event, field) => {
        // separating the inputfield
        const [dimensionInput, dimensionField, dimensionCode] = field.name.split("-");
        let dimension = {};
        if (dimensionCode) {
            // processing update to the dimension scale
            dimension = {
                ...formState[0][dimensionInput],
                [dimensionField]: {
                    [dimensionCode]: event.currentTarget.value
                }
            };
        } else {
            // processing update to the dimension value
            dimension = {
                ...formState[0][dimensionInput],
                [dimensionField]: parseFloat(event.currentTarget.value) || ""
            };
        }

        const fakeEvent = {
            currentTarget: {
                name: dimensionInput,
                value: dimension
            }
        };
        formUpdate(fakeEvent);
        setIsValid(validate(event.currentTarget.value, field));
    };

    return (
        <div className="d-flex flex-column mb-3">
            {input.displayLabel && <label htmlFor={input.name} className="form-label">{input.label}</label>}
            <div className="d-flex flex-row input-group mb-2">
                <input type={input.type} className="form-control form-control-lg" id={input.name} name={input.name} aria-describedby={`${input.name}Help`} placeholder={input.placeholder}
                    value={inputValue}
                    onChange={(event) => handleInputChange(event, input)}

                />
                <button className="btn btn-outline-primary dropdown-toggle col-4 col-sm-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" >{dropDownValue}</button>
                <ul className="dropdown-menu dropdown-menu-end" >
                    {
                        dropDownList.map(setting => {
                            return (<li className="d-flex list-group-item gap-2 justify-content-end border-0 bg-transparent" key={setting.code} >
                                <button className="btn fs-4 d-flex gap-2" type={dropDown.type} name={dropDown.name} id={`${dropDown.name}-${setting.code}`} value={setting.code} data-bs-toggle="" aria-expanded="false" onClick={(event) => handleInputChange(event, dropDown)}>
                                    <span className="d-flex">{setting.name}</span><span className="d-flex text-primary">{setting.code}</span>
                                </button>
                            </li>);

                        })
                    }
                </ul>

            </div>
            {!isValid && <Error message={input.error} />}
        </div>

    );
};

export default InputDropdownField;