// external libraries
import { createSelector } from '@reduxjs/toolkit';

// redux slice
import { selectProductById } from '../products/productsSelectors';

// defining selectors based on the state
// selects and returns the product id for products in the wishlist
export const selectWishlistProductIds = createSelector(
    [state => state.wishlist],
    (wishlist) => {
        return wishlist.products.map(product => product.id);
    }
);

// returns the wishlist products from the state in the format {id: product.id} 
export const selectWishlistProducts = createSelector(
    [state => state.wishlist],
    (wishlist) => {
        return wishlist.products.map(product => { return { id: product.id }; });
    }
);

// array.find() is used to find the product in the wishlist by the product id
export const selectWishlistProductById = createSelector(
    [state => state.wishlist, (state, productId) => productId],  
    (wishlist, productId) => {
        return wishlist.products.find(product => product.id === productId);
    }
);

// returns all details for the products in the wishlist; 
// since the products in the wishlist are store as { id: product.id }, we need to get the product details from the products slice
export const selectAllWishlistProductDetails = createSelector(
    [(state) => state.wishlist.products, (state) => state],
    (wishlistProducts, state) => {
        return wishlistProducts.map((wishlistProduct) => {
            return {
                ...wishlistProduct,
                ...selectProductById(state, wishlistProduct.id)
            };
        });
    }
);