// external libraries
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// renders the message and icon to the page. This component is used by the landing list to display all messages in the same look and feel
const Message = ({ icon, message }) => {
    return (
        <div className="card d-flex m-2 p-2 border border-2 shadow-sm" >
            <div className="card-body d-flex flex-column justify-content-start align-items-center">
                <h5 className="card-title d-flex fs-1 mb-3"><FontAwesomeIcon icon={icon} /></h5>
                <p className="card-text d-flex fs-5">{message}</p>
            </div>
        </div>
    );
};

export default Message;

