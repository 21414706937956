// external libraries
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

// custom CSS
import "./index.css";


// potential for future development where links to the banners are saved in a database and retrieved dynamically
// this way the website can take any number of banners that the owner decided
const banners = [
    { "url": "/media/non-products/banners/slide_8.JPG" },
    { "url": "/media/non-products/banners/slide_4.JPG" },
    { "url": "/media/non-products/banners/slide_7.JPG" }
];

// displays the banners on the landing page
// within the banner there's a button to view the product listing
const Banner = () => {
    return (
        <div id="carouselExampleAutoplaying" className="carousel slide carousel-fade shadow" data-bs-ride="carousel" data-bs-interval="5000">
            <div className="carousel-inner">
                {banners.map((banner, index) => {
                    return (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                            <img src={banner.url} className="d-block w-100" alt={`banner slide ${index}`} />
                            {/* currently the caption text with link to all products is disabled as it doesn't show well on mobile devices */}
                            <div className="carousel-caption d-flex flex-column justify-content-end align-items-end d-none ">
                                <Link to={"/products"} className="text-decoration-none">
                                    <button type="button" className="btn btn-primary fs-4 mb-2">View products</button>
                                </Link>
                                <p className="fs-4 bg-secondary p-2 rounded">Click to view our selection of handmade prodcuts. <FontAwesomeIcon icon={ faArrowUp } /></p>
                            </div>
                        </div>);

                })}

            </div>

        </div>

    );
};

export default Banner;

