// external libraries
import React from "react";

// components
import InputField from "../../../../common/FormFields/InputField";

// utulities
import { productRegularPriceFieldParams, productSpecialFromFieldParams, productSpecialPriceFieldParams, productSpecialToFieldParams } from "../../../../../utils/fieldParameters";


// the pricing input is an array of pricing objects. Each pricing object has the following:
//  {
//      userGroup: the usergroup for the price that is being entered
//      regular: regular price for the user group. This in turn is an object containing the currency information and the value
//      special: special (sale) price for the user group. In additionl to pricing object, the specials incluide date for which the duration of the special price lasts
//  }
// the component renders an input are for all customer groups
// the naming of the fields follows the format
// userGroupId-regular; userGroupId-special; userGroupId-special-dates-fromDate; userGroupId-special-dates-toDate - all separated by hyphen(-)
// this way it is easier to split the input and update the correct field
const AdminPriceInput = ({ price, formUpdate, formState, userGroup }) => {
    const { regular, special } = price;

    const handlePricingChange = (event) => {
        event.preventDefault();
        // separates the components of the input price
        const [userGroupId, pricingType, dateInput, dateField] = event.currentTarget.name.split("-");
        let pricingInput = formState[0].pricing.map(price => {
            if (price?.userGroup?.id === userGroupId) {
                // if the dateInput and dateField are null, that means the price was upated
                if (!dateInput && !dateField) {
                    // checks if the price is entered and if not returns null for the pricing input
                    if (event.currentTarget.value === "" || isNaN(event.currentTarget.value)) {
                        return {
                            ...price,
                            [pricingType]: null

                        };
                    } else {
                        // changes to price input
                        return {
                            ...price,
                            [pricingType]: {
                                ...price[pricingType],
                                currency: {
                                    code: "CAD" // defaulting to CAD until the currenct dropdown is implemented
                                },
                                value: parseFloat(event.currentTarget.value) || ""
                            }
                        };
                    }
                } else {
                    // changes to the date input
                    return {
                        ...price,
                        [pricingType]: {
                            ...price[pricingType],
                            [dateInput]: {
                                ...price[pricingType][dateInput],
                                [dateField]: event.currentTarget.value || ""
                            }
                        }
                    };
                }
            } else {
                // no changes to the pricing input were made
                return { ...price };
            }
        });
        // since the pricing structure is complex, a regular event cannot be used
        // creating the fake event to pass to the formUpdate function
        // the pricing input must be filtered to remove prices for userGroups that do not have regular pricing
        const fakeEvent = {
            currentTarget: {
                name: 'pricing',
                value: pricingInput
            }
        };
        formUpdate(fakeEvent);

    };

    // displaying the price input per user group
    // even though some information is present in the fieldParams, information is overwritten in order to accomodate the complex structure
    return (
        <div className="d-flex flex-column col-12 mb-3 p-3 justify-content-start align-items-start border rounded shadow ">
            <div className="text-secondary fs-5 mb-2">
                Price for {userGroup?.name}
            </div>
            <div className="d-flex flex-column p-2 border rounded border-1 shadow-sm col-12 mb-2 bg-light">
                <InputField {...productRegularPriceFieldParams} value={regular?.value || ""} formUpdate={(event) => handlePricingChange(event)} name={`${userGroup.id}-regular`} />
            </div>
            <div className="d-flex flex-column p-2 border rounded border-1 shadow-sm col-12 mb-2 bg-light">
                <InputField {...productSpecialPriceFieldParams} value={special?.value || ""} formUpdate={(event) => handlePricingChange(event)} name={`${userGroup.id}-special`} />
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <InputField {...productSpecialFromFieldParams} value={special?.dates?.fromDate?.slice(0, 19) || ""} formUpdate={(event) => handlePricingChange(event)} name={`${userGroup.id}-special-dates-fromDate`} />
                    <InputField {...productSpecialToFieldParams} value={special?.dates?.toDate?.slice(0, 19) || ""} formUpdate={(event) => handlePricingChange(event)} name={`${userGroup.id}-special-dates-toDate`} />
                </div>
            </div>
        </div>
    );
};

export default AdminPriceInput;