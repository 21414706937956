// extermal libraries
import { gql } from '@apollo/client';

// graphql query to create or update a product
// when id is passed, the product is updated
// when no id is passed, the product is created
// productInputType is defined in django
export const UPSERT_PRODUCT = gql`
mutation UpsertProduct($productData: ProductInputType!, $id: ID) {
  upsertProduct(productData: $productData, id: $id) {
    product {
    id
    sku
    name
    description
    isActive
    images {
      url
      isMain
    }   
    categories {
      id
      isActive
      name
      parent {
        id
        isActive
        name
      }
    }
    weight {
      scale {
        name
        code
      }
      value
    }
    length {
      scale {
        name
        code
      }
      value
    }    
    width {
      scale {
        name
        code
      }
      value
    }  
    height {
      scale {
        name
        code
      }
      value
    }
    modifiedAt
    pricing {
      userGroup {
        id
        name
      }
      regular {
        currency {
          code
          name
          symbol {
            left
            right
          }
        }
        value
      }
      special {
        currency {
          code
          name
          symbol {
            left
            right
          }
        }
        value
        dates {
          fromDate
          toDate
        }        

      }
    }

    }
  }
}
`;