// external libraries
import React from "react";
import { useSelector } from "react-redux";

// components
import DisplayProductCard from "../Card";

// redux    
import { selectAllProducts, selectProductsByCategory } from "../../../../../features/products/productsSelectors";
import { SUCCESS } from "../../../../../features/status";

// custom css
import "./index.css";


// product component list - this is used to display
// - subsection of products under the features products on the landing page
// - full list of products on the products page; if user selects a filter, products are filtered
const ProductList = ({ title, maxCount, wrap = true, applyFilter = false }) => {
    const success = useSelector(state => state.products.status === SUCCESS);

    // get the products from the global state depending on the presence of a filter
    let productList = useSelector(state => {
        return applyFilter && success ? selectProductsByCategory(state) : selectAllProducts(state);
    });
    // select maxCount number of products - mainly for the featured products on the landing page
    if (success && maxCount) {
        productList = productList.slice(0, maxCount);
    }
    // if there are no products, display a message
    if (success && productList.length === 0) {
        return <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <div className="d-flex flex-column justify-content-center align-items-center fs-1 mb-2 text-secondary col-12">
                <span className="fst-italic">No Products to Show</span>
            </div>
        </div>;
    }
    // if there are products, display the products
    return (
        <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <div className="d-flex flex-column justify-content-center align-items-center fs-1 mb-2 text-secondary col-12">
                <span className="fst-italic">{title}</span>
            </div>
            <div id={"product_list"} className="d-flex overflow-x-auto col-12 col-md-10 p-2 justify-content-center">
                <div className={`d-flex ${wrap && "flex-wrap"} col-12 justify-content-start`}>
                    {
                        productList.map((product) => {
                            return (
                                <div className="d-flex p-2 col-11 col-sm-5 col-md-4 col-xl-3" key={product.id}>
                                    <DisplayProductCard product={product} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductList;