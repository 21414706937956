// external libraries
import {
  ApolloProvider,
} from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";

// components
import Nav from "./components/common/Nav";
import Footer from "./components/common/Footer";
import AdminRoute from "./components/admin/Route";
import CustomerRoute from "./components/customer/Route";
import ProtectedRoute from "./components/common/Route/Protected";

// pages
import Landing from "./pages/common/Landing";
import Authentication from "./pages/common/Authentication";
import Logout from "./pages/common/Logout";
import Products from "./pages/customer/Products";
import Success from "./pages/customer/Success";
import WishlistPage from "./pages/customer/Wishlist";
import Orders from "./pages/customer/Orders";
import Product from "./pages/customer/Product";
import CartPage from "./pages/customer/Cart";
import DisclaimerPage from "./pages/common/Information/Disclaimer";
import AdminProducts from "./pages/admin/Products";
import AdminProduct from "./pages/admin/Product";
import Privacy from "./pages/common/Information/Privacy";
import Terms from "./pages/common/Information/Terms"; 
import NotFound from "./pages/common/NotFound";

// redux slice
import { fetchProducts } from "./features/products/productsSlice";
import { fetchUserData } from "./features/user/userSlice";
import { fetchUISettings, fetchAdminUISettings } from "./features/ui/uiSlice";

// providers
import { store } from "./app/store";
import { client } from "./app/apollo";

// custom css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";


function App() {

  const [csrfCookie] = useState(Cookie.get("csrftoken"));

  useEffect(() => {
    /* if there"s no cookie named csrftoken, reload the page. In dev at the time of application start
     * the schema retrieval will experience a 403 error. HOwever, at the same time as the response, the csrfcookie will be set
     * therefore, the page is reloaded so that it takes the csrftoken and properly sets up the apollo client
    */
    if (csrfCookie === "") {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [csrfCookie]);

  useEffect(() => {
    // fetch the user data and if the user is staff, fetch the admin ui settings
    store.dispatch(fetchUserData()).then((user) => {
      if (user?.payload?.data?.userData?.isStaff) {
        store.dispatch(fetchAdminUISettings());
      }
    });
    // fetch the products
    store.dispatch(fetchProducts());
    // fetch the UI settings
    store.dispatch(fetchUISettings());
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>

        <Router>
          <div className="d-flex justify-content-center align-items center">
            <div className="d-flex flex-column col-12 col-xxl-10 ">
              <Nav />
              <Routes>
                <Route path="/" element={
                  <CustomerRoute>
                    <Landing />
                  </CustomerRoute>
                } />
                <Route path="/login" element={
                  <Authentication login={true} />
                } />
                <Route path="/register" element={
                  <Authentication registration={true} />
                } />
                <Route path="/logout" element={
                  <Logout />
                } />
                <Route path="/products" element={
                  <CustomerRoute>
                    <Products />
                  </CustomerRoute>
                } />
                <Route path="/products/:id" element={
                  <CustomerRoute>
                    <Product />
                  </CustomerRoute>
                } />
                <Route path="/cart" element={
                  <CustomerRoute>
                    <ProtectedRoute >
                      <CartPage />
                    </ProtectedRoute>
                  </CustomerRoute>
                } />
                <Route path="/wishlist" element={
                  <CustomerRoute>
                    <ProtectedRoute >
                      <WishlistPage />
                    </ProtectedRoute>
                  </CustomerRoute>
                } />
                <Route path="/orders" element={
                  <CustomerRoute>
                    <ProtectedRoute >
                      <Orders />
                    </ProtectedRoute>
                  </CustomerRoute>
                } />
                <Route path="/success" element={
                  <CustomerRoute>
                    <ProtectedRoute >
                      <Success />
                    </ProtectedRoute>
                  </CustomerRoute>
                } />
                <Route path="/disclaimer" element={
                  <DisclaimerPage />
                } />
                <Route path="/privacy" element={
                  <Privacy />
                } />
                <Route path="/terms" element={
                  <Terms />
                } />
                <Route path="/admin" element={
                  <AdminRoute>
                    <ProtectedRoute >
                      <AdminProducts />
                    </ProtectedRoute>
                  </AdminRoute>
                } />
                <Route path="/admin/products/:id" element={
                  <AdminRoute>
                    <ProtectedRoute >
                      <AdminProduct />
                    </ProtectedRoute>
                  </AdminRoute>
                } />
                <Route path="/admin/products/new" element={
                  <AdminRoute>
                    <ProtectedRoute >
                      <AdminProduct />
                    </ProtectedRoute>
                  </AdminRoute>
                } />
                <Route path="/admin/products" element={
                  <AdminRoute>
                    <ProtectedRoute >
                      <AdminProducts />
                    </ProtectedRoute>
                  </AdminRoute>
                } />
                <Route path="*" element={<NotFound />} /> 
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    </ApolloProvider >
  );
}

export default App;
