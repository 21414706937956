// external libraries
import { gql } from '@apollo/client';

// graphql query to add products to the cart
export const MUTATION_ADD_TO_CART = gql`
mutation AddItemsToCart($products: [CartProductInputType], $id: ID) {
  upsertCart(cartData: {products: $products}, id: $id) {
    cart {
      id
      products {
        product {
          id
        }
        quantity
      }
    }
  }
}
`;

// graphql query to delete the cart. Cart is deleted when user places an order. Otherwise cart persists
export const MUTATION_DELETE_CART = gql`
mutation deleteCart($id: ID) {
  deleteCart(id: $id) {
    success
  }
}
`;