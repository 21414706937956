// external libraries
import { gql } from '@apollo/client';


// query to get the currencies and customer groups saved in the application. Required for the admin UI
export const QUERY_ADMIN_UI_SETTINGS = gql`
query AdminUISettings {
  customerGroups {
    id
    name
  }
  currencies {
    id
    name
    code
    factor
    symbol {
      left
      right
    }
    isActive
  }
}`;

// query to get the generic Ui settings applicable to both customers and staff
export const QUERY_UI_SETTINGS = gql`
query uiSettings{
  weight {
    id
    name
    code
  }
  length {
    id
    name
    code
  }
  categories {
      id
      name
      isActive
      parent {
        id
        name
        isActive
      }
    }
}`;
