// external libraries
import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";

// components
import FormContainer from "../../../components/common/FormContainer";
import LoginForm from "../../../components/common/LoginForm";
import RegistrationForm from "../../../components/common/RegistrationForm";


// Authentication page - this is the page where the user can login or register
const Authentication = ({ login = false, registration = false }) => {
    const location = useLocation();
    const redirectTo = location?.state?.from?.pathname || "/";
    let form;
    if (login) {
        form = <LoginForm redirectTo={redirectTo} />;
    }
    if (registration) {
        form = <RegistrationForm redirectTo={redirectTo} />;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<main className="d-flex flex-column">
        <FormContainer form={form}/>
    </main>);
};

export default Authentication;

