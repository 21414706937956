// external libraries
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

// redux slice
import { LOADING, SUCCESS, FAILED, IDLE, LOGIN_ERROR } from '../status';
import { MUTATION_ADD_TO_CART, MUTATION_DELETE_CART } from './cartMutations';
import { fetchUserData } from '../user/userSlice';

// apollo client
import { client } from '../../app/apollo';

// creating the cart adapter as per redux documentation
export const cartAdapter = createEntityAdapter();

// initial state of the cart slice
const initialState = {
    status: IDLE,
    error: null,
    id: "",
    products: []
};


// async thunk to add items to the cart
export const addItemToCart = createAsyncThunk(
    'cart/addItemToCart',
    async (products, { rejectWithValue }
    ) => {
        try {
            const response = await client.mutate({
                mutation: MUTATION_ADD_TO_CART,
                variables: { products: products },
                fetchPolicy: 'network-only'
            });
            if (!response?.data?.upsertCart?.cart) {
                return rejectWithValue({ "login_error": true });
            }

            return response;
        } catch (error) {
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });

// async thunk to delete the cart
export const deleteCart = createAsyncThunk(
    'cart/deleteCart',
    async (cartId, { rejectWithValue }
    ) => {
        try {
            const response = await client.mutate({
                mutation: MUTATION_DELETE_CART,
                variables: { id: cartId },
                fetchPolicy: 'network-only'
            });
            if (!response?.data?.deleteCart?.success) {
                return rejectWithValue({ "login_error": true });
            }

            return response;
        } catch (error) {
            return rejectWithValue({ errors: error.graphQLErrors });
        }
    });


// creating cart slice as per redux documentation
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        cartReset(state, action) {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            // after fetchUserData is successful, each component goes into it's own slice. the cart goes into the cart slice
            // if the fetchUserData is rejected, the intital state is returned
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.id = action?.payload?.data?.cart?.id || "";
                state.products = action?.payload?.data?.cart?.products || [];
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                return {...initialState};
            })
            // adding items to cart:
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and the cart is updated with the new items
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(addItemToCart.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(addItemToCart.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.id = action?.payload?.data?.upsertCart?.cart?.id || "";
                state.products = action?.payload?.data?.upsertCart?.cart?.products || [];
                state.error = null;
                
            })
            .addCase(addItemToCart.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {...initialState, 
                    status: login_error ? LOGIN_ERROR : FAILED,
                    erorr: login_error ? LOGIN_ERROR : FAILED
                };
            })
            // deleting the cart after successful payment:
            //  - pending: status is set to loading
            //  - fulfilled: status is set to success and initial state is returned
            //  - rejected: the intital state is returned and the status and errors are set to either login_error or an error
            .addCase(deleteCart.pending, (state, action) => {
                state.status = LOADING;
                state.error = null;
            })
            .addCase(deleteCart.fulfilled, (state, action) => {
                
                return { ...initialState, status: SUCCESS };
            })
            .addCase(deleteCart.rejected, (state, action) => {
                const login_error = action.payload?.errors.some(error => error?.extensions?.login_error) || action.payload?.errors.login_error;
                return {...initialState, 
                    status: login_error ? LOGIN_ERROR : FAILED,
                    error: login_error ? LOGIN_ERROR : FAILED

                };
            });
    },
});

// exoprting the actions
export const { cartReset } = cartSlice.actions;

// exporting the reducer
export default cartSlice.reducer;

