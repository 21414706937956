// external libraries
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram, faFacebook, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";


// footer component showing all social media links with links to them
const Footer = () => {
    return (
        <footer className="d-flex flex-column flex-sm-row position-absolute bottom-0 col-12 ">
            <div className="d-flex col-12 bg-body-secondary h-100 justify-content-center align-items-center">
                <div className="fs-1">
                    <Link to={"https://www.youtube.com/@kokaboracreations"} target="blank" className="text-reset"><FontAwesomeIcon icon={faYoutube} className="m-2" /></Link>
                    <Link to={"https://www.instagram.com/kokaboracreations"} target="blank" className="text-reset"><FontAwesomeIcon icon={faInstagram} className="m-2" /></Link>
                    <Link to={"https://www.facebook.com/kokaboracreations"} target="blank" className="text-reset"><FontAwesomeIcon icon={faFacebook} className="m-2" /></Link>
                    <Link to={"https://www.pinterest.com/kokaboracreate"} target="blank" className="text-reset"><FontAwesomeIcon icon={faPinterest} className="m-2" /></Link>
                    <Link to={"https://www.twitter.com/kokaboracreate"} target="blank" className="text-reset"><FontAwesomeIcon icon={faXTwitter} className="m-2" /></Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

